import Header from "../components/header/HeaderThree";
import CertificateList from "../sub-pages/certificates/CertificateList";
import Footer from "../components/footer/Footer";
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../components/common/Helmet";
import Breadcrumb from "../components/common/Breadcrumb";
import PageScrollTop from "../components/pageScrollTop/PageScrollTop";

export default function Certifications() {
	const { showBackToTop, scrollToTop } = PageScrollTop();

	return (
		<>
			<Helmet pageTitle="Certifications" />
			<Header />

			{/* Start Breadcrump Area */}
			<Breadcrumb title={"Our Certifications"} page={"certifications"} />
			{/* End Breadcrump Area */}

			{/* Start Certificates Area  */}
			<div id="service" className="fix">
				<div className="service-area creative-service-wrapper ptb--60 bg_color--5">
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="section-title text-left service-style--3 mb--30 mb_sm--0">
									<h2
										className="title"
										style={{
											borderBottom: "0.7px solid",
											marginBottom: "20px",
										}}
									>
										Our Certifications
									</h2>
								</div>
								<div className="col-lg-12 mb--30">
									<p>
										Whether you are new or already established in the testing
										profession, ISTQB® certification will support you throughout
										your career. For employers, the ISTQB® Certified Tester
										scheme will help you to develop and validate the skills in
										your team, and support recruitment. ISTQB® certifications
										offer Training Providers multiple opportunities to extend
										their training course portfolios. Click on each
										certification for more information.
									</p>
								</div>
							</div>
						</div>
						<div className="row creative-service">
							<div className="col-lg-12">
								<h3
									style={{
										backgroundColor: "#107fc9",
										color: "white",
										textAlign: "center",
										marginTop: "30px",
										marginBottom: "40px",
									}}
								>
									Core
								</h3>
								<CertificateList
									type="foundation"
									column="col-lg-4 col-md-6 col-sm-6 col-12 text-left"
								/>
								<h3
									style={{
										backgroundColor: "#107fc9",
										color: "white",
										textAlign: "center",
										marginTop: "40px",
										marginBottom: "10px",
									}}
								>
									Agile
								</h3>
								<CertificateList
									type="agile"
									column="col-lg-4 col-md-6 col-sm-6 col-12 text-left"
								/>
								<h3
									style={{
										backgroundColor: "#107fc9",
										color: "white",
										textAlign: "center",
										marginTop: "40px",
										marginBottom: "10px",
									}}
								>
									Advanced
								</h3>
								<CertificateList
									type="advanced"
									column="col-lg-4 col-md-6 col-sm-6 col-12 text-left"
								/>
								<h3
									style={{
										backgroundColor: "#107fc9",
										color: "white",
										textAlign: "center",
										marginTop: "40px",
										marginBottom: "10px",
									}}
								>
									Specialist
								</h3>
								<CertificateList
									type="specialist"
									column="col-lg-4 col-md-6 col-sm-6 col-12 text-left"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* End Certificates Area  */}

			{/* Start Back To Top */}
			{showBackToTop && (
				<div className="backto-top" style={{ display: showBackToTop ? "block" : "none" }}>
					<div>
						<div onClick={scrollToTop} className="backto-top">
							<FiChevronUp />
						</div>
					</div>
				</div>
			)}
			{/* End Back To Top */}

			{/* Start Footer Style  */}
			<Footer />
			{/* End Footer Style  */}
		</>
	);
}
