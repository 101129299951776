import PageHelmet from "../components/common/Helmet";
import { FiHeadphones, FiMail, FiMapPin } from "react-icons/fi";
import ContactTwo from "../components/contact/ContactTwo";
import { FiChevronUp } from "react-icons/fi";
import Header from "../components/header/HeaderThree";
import Footer from "../components/footer/Footer";
import Breadcrumb from "../components/common/Breadcrumb";
import PageScrollTop from "../components/pageScrollTop/PageScrollTop";

export default function Contact() {
	const { showBackToTop, scrollToTop } = PageScrollTop();

	return (
		<>
			<PageHelmet pageTitle="Contact" />
			<Header />

			{/* Start Breadcrump Area */}
			<Breadcrumb title={"Contact Us"} page={"contact"} />
			{/* End Breadcrump Area */}

			{/* Start Contact Top Area  */}
			<div className="rn-contact-top-area ptb--60 bg_color--5">
				<div className="container">
					<div className="row">
						{/* Start Single Address  */}
						<div className="col-lg-3 col-md-6 col-sm-6 col-12">
							<div className="rn-address">
								<div className="inner">
									<h4 className="title">Working Hours and Days</h4>
									<p>Monday - Friday</p>
									<p>10:00AM - 07:00PM</p>
								</div>
							</div>
						</div>
						{/* End Single Address  */}

						{/* Start Single Address  */}
						<div className="col-lg-3 col-md-6 col-sm-6 col-12">
							<div className="rn-address">
								<div className="inner">
									<h4 className="title">Phone Number</h4>
									<p>
										<a href="tel:+374 25 43654">+374 77 451727</a>
									</p>
								</div>
							</div>
						</div>
						{/* End Single Address  */}

						{/* Start Single Address  */}
						<div className="col-lg-3 col-md-6 col-sm-6 col-12">
							<div className="rn-address">
								<div className="inner">
									<h4 className="title">Email Address</h4>
									<p>
										<a href="mailto:info@armstqb.org">info@armstqb.org</a>
									</p>
									<p>
										<a href="mailto:exam@armstqb.org">exam@armstqb.org</a>
									</p>
								</div>
							</div>
						</div>
						{/* End Single Address  */}

						{/* Start Single Address  */}
						<div className="col-lg-3 col-md-6 col-sm-6 col-12">
							<div className="rn-address">
								<div className="inner">
									<h4 className="title">Location</h4>
									<p>Tigran Mets 80, </p>
									<p>Impact Hub - 3rd floor</p>
									<p>Yerevan, Armenia</p>
								</div>
							</div>
						</div>
						{/* End Single Address  */}
					</div>
				</div>
			</div>
			{/* End Contact Top Area  */}

			{/* Start Contact Page Area  */}
			<div className="rn-contact-page ptb--60 bg_color--1">
				<ContactTwo />
			</div>
			{/* End Contact Page Area  */}

			{/* Start Back To Top */}
			{showBackToTop && (
				<div className="backto-top" style={{ display: showBackToTop ? "block" : "none" }}>
					<div>
						<div onClick={scrollToTop} className="backto-top">
							<FiChevronUp />
						</div>
					</div>
				</div>
			)}
			{/* End Back To Top */}

			<Footer />
		</>
	);
}
