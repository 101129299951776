import PageHelmet from "../../components/common/Helmet";
import { FiChevronUp } from "react-icons/fi";
import Header from "../../components/header/HeaderThree";
import Footer from "../../components/footer/Footer";
import ctatLogo from "../../assets/images/logos/ctfl_at_logo.png";
import ctatTable from "../../assets/images/tables/ctfl_at_table.png";
import ExamStructure from "./ExamStructure";
import "bootstrap/js/src/modal";
import PageScrollTop from "../../components/pageScrollTop/PageScrollTop";

const businessOutcomesList = [
	"Collaborate in a cross-functional Agile team being familiar with principles and basic practices of Agile software development.",
	"Adapt existing testing experience and knowledge to Agile values and principles.",
	"Support the Agile team in planning test-related activities.",
	"Apply relevant methods and techniques for testing in an Agile project.",
	"Assist the Agile team in test automation activities.",
	"Assist business stakeholders in defining understandable and testable user stories, scenarios, requirements, and acceptance criteria as appropriate.",
	"Work and share information with other team members using effective communication styles and channels.",
];

export default function AgileTester() {
	const { showBackToTop, scrollToTop } = PageScrollTop();

	const showBusinessOutcomes = businessOutcomesList.map((item, index) => {
		return <li key={index}>{item}</li>;
	});

	return (
		<>
			{/* Start Pagehelmet  */}

			<PageHelmet pageTitle="ISTQB Foundation Level Agile Tester" />
			{/* End Pagehelmet  */}

			<Header />

			{/* Start Breadcrump Area */}
			<div
				className="breadcrumb-area rn-bg-color pt--20 pb--60 bg_image bg_image--certifications"
				data-black-overlay="6"
			>
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="rn-page-title text-center pt--100">
								<h2 className="title theme-gradient">
									Foundation Level Agile Tester
								</h2>
								<p>Certified Tester Foundation Level Agile Tester (CTFL-AT)</p>
								<div className="portfolio-button">
									<a
										className="rn-button-style--2 btn-solid-register"
										href="http://bit.ly/3jatMF3"
										target="_blank"
									>
										Register Now
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* End Breadcrump Area */}

			{/* Start Page Wrapper */}
			<div className="rn-service-details ptb--30 bg_color--1">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="service-details-inner">
								<div className="inner">
									{/* Start Single Area */}
									<div className="row sercice-details-content pb--20 align-items-center">
										<div className="col-lg-10 col-12">
											<div className="details mt_md--30 mt_sm--30">
												<h4 className="title">Overview</h4>
												<p>
													The ISTQB® Foundation Level Agile Tester
													(CTFL-AT) certification provides the key
													testing skills necessary to successfully
													contribute to an Agile project. CTFL-AT
													covers agile testing in alignment with the
													principles of agile software development as
													outlined in the Agile Manifesto. A tester on
													an Agile project will work differently than
													one working on a traditional project. Testers
													must understand the values and principles
													that underpin Agile projects, and how testers
													are an integral part of a whole-team approach
													together with developers and business
													representatives.
												</p>

												<h4 className="title">Audience</h4>
												<p>
													The Foundation Level Agile Tester
													certification is designed for professionals
													who are working within Agile environments. It
													is also for professionals who are planning to
													start implementing Agile methods in the near
													future, or are working within organizations
													that plan to do so.
													<br />
													To gain this certification, candidates must
													hold the{" "}
													<a href="/certifications/certified-tester-foundation-level">
														<b>
															Certified Tester Foundation Level{" "}
														</b>
													</a>
													certificate.
												</p>
											</div>
										</div>
										<div className="col-lg-2 col-4">
											<div className="thumb">
												<img
													className="w-100 d-lg-block d-md-none"
													src={ctatLogo}
													alt="CTAT Logo"
													data-bs-toggle="modal"
													data-bs-target="#ctatLogoModal"
												/>
											</div>
										</div>

										{/* MODAL START*/}
										<div
											className="modal fade"
											id="ctatLogoModal"
											tabIndex="-1"
											aria-hidden="true"
										>
											<div className="modal-dialog">
												<div className="modal-content">
													<div className="modal-body">
														<button
															type="button"
															className="btn-close"
															data-bs-dismiss="modal"
															aria-label="Close"
														></button>
														<img
															className="w-100 d-lg-block d-md-none"
															src={ctatLogo}
														/>
													</div>
												</div>
											</div>
										</div>
										{/* MODAL END*/}
									</div>
									{/* End Single Area */}

									{/* Start Single Area */}
									<div className="row sercice-details-content pb--20 align-items-center">
										<div className="col-lg- col-12 order-2 order-lg-1">
											<div className="details mt_md--30 mt_sm--30">
												<h4 className="title">Contents</h4>
												<div
													className="thumb"
													style={{ paddingTop: "13px" }}
												>
													<img
														className="w-100"
														src={ctatTable}
														alt="CTAL Content Table"
														data-bs-toggle="modal"
														data-bs-target="#ctatTableModal"
													/>
												</div>
												{/* MODAL START*/}
												<div
													className="modal fade"
													id="ctatTableModal"
													tabIndex="-1"
													aria-hidden="true"
												>
													<div
														style={{
															width: "auto",
															height: "auto",
															maxWidth: "100%",
															maxHeight: "100%",
															display: "block",
															margin: "auto",
														}}
														className="modal-dialog"
													>
														<div className="modal-content">
															<div
																style={{
																	width: "auto",
																	height: "auto",
																	maxWidth: "100%",
																	maxHeight: "100%",
																	display: "block",
																	margin: "auto",
																}}
																className="modal-body"
															>
																<button
																	type="button"
																	className="btn-close"
																	data-bs-dismiss="modal"
																	aria-label="Close"
																></button>
																<img
																	style={{
																		width: "auto",
																		height: "auto",
																		maxWidth: "100%",
																		maxHeight: "100%",
																		display: "block",
																		margin: "auto",
																	}}
																	// className="w-100 d-lg-block d-md-none"
																	src={ctatTable}
																/>
															</div>
														</div>
													</div>
												</div>
												{/* MODAL END */}
											</div>
										</div>
									</div>
									{/* End Single Area */}

									{/* Start Single Area */}
									<div className="row sercice-details-content pb--20 align-items-center">
										<div className="col-lg-12 col-10 order-2 order-lg-4">
											<div className="details mt_md--30 mt_sm--30">
												<h4 className="title">Exam Structure</h4>
												<ExamStructure exam="ctat" />
											</div>
										</div>
									</div>
									{/* End Single Area */}

									{/* Start Single Area */}
									<div className="row sercice-details-content pb--20 align-items-center">
										<div className="col-lg- col-12 order-2 order-lg-1">
											<div className="details mt_md--30 mt_sm--30">
												<h4 className="title">Business Outcomes</h4>
												<p>
													A candidate who has achieved the Foundation
													Level certification should be able to:
												</p>
												<ul className="liststyle">
													{showBusinessOutcomes}
												</ul>
											</div>
										</div>
									</div>
									{/* End Single Area */}

									{/* Start Single Area */}
									<div className="row sercice-details-content pb--20 align-items-center">
										<div className="col-lg- col-12 order-2 order-lg-1">
											<div className="details mt_md--30 mt_sm--30">
												<h4 className="title">More Information</h4>
												<p>
													Training is available from Accredited
													Training Providers (classroom, virtual, and
													e-learning). We highly recommend attending
													accredited training as it ensures that an
													ISTQB® Member Board has assessed the
													materials for relevance and consistency
													against the syllabus.
													<br />
													Self-study, using the syllabus and
													recommended reading material, is also an
													option when preparing for the exam.
													<br />
													<b>
														Holders of this certification will be
														eligible to proceed to the next stage of
														the Agile stream and take the Agile
														Technical Tester (CTAL-ATT) and Agile
														Test Leadership at Scale (CTAL-ATLaS)
														certifications.
													</b>
												</p>
											</div>
										</div>
									</div>
									{/* End Single Area */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* End Page Wrapper */}

			{/* Start Back To Top */}
			{showBackToTop && (
				<div className="backto-top" style={{ display: showBackToTop ? "block" : "none" }}>
					<div>
						<div onClick={scrollToTop} className="backto-top">
							<FiChevronUp />
						</div>
					</div>
				</div>
			)}
			{/* End Back To Top */}

			<Footer />
		</>
	);
}
