import PageHelmet from "../../../components/common/Helmet";
import { FiChevronUp } from "react-icons/fi";
import Header from "../../../components/header/HeaderThree";
import Footer from "../../../components/footer/Footer";
import poster from "../../../assets/images/news/news_21_06_2024/armsoft-istqb.jpg";
import PageScrollTop from "../../../components/pageScrollTop/PageScrollTop";

export default function ArmsoftNews() {
	const { showBackToTop, scrollToTop } = PageScrollTop();

	return (
		<>
			{/* Start Pagehelmet  */}
			<PageHelmet pageTitle="ISTQB® Platinum Partnership with Armsoft extended for another year." />
			{/* End Pagehelmet  */}

			<Header />

			{/* Start Breadcrump Area */}
			<div className="rn-page-title-area pt--20 pb--60" data-black-overlay="7">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="rn-page-title text-center pt--100">
								<h2 className="title theme-gradient">
									ISTQB® Platinum Partnership with Armsoft extended for another year.
								</h2>
								<p>21/06/2024</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* End Breadcrump Area */}

			{/* Start Page Wrapper */}
			<div className="rn-service-details ptb--30 bg_color--1">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="service-details-inner">
								<div className="inner">
									{/* Start Single Area */}
									<div className="row sercice-details-content pb--20 align-items-center">
										<div className="col-lg-6 col-12">
											<div className="details mt_md--30 mt_sm--30">
												<h4 className="title">Overview</h4>
												<p>
													This partnership milestone in Armenia continues to be a
													significant achievement, and we are excited to further
													our journey together. With ArmSoft by our side, we are
													poised to reach new heights of success and deliver
													exceptional value.
												</p>
												<p>
													We're overjoyed to announce that the ISTQB Partnership
													with ArmSoft has been extended for another year! This
													renewal of our Platinum Partnership marks another
													significant milestone in Armenia, reinforcing our
													shared commitment to excellence and innovation.
												</p>
												<p>
													We invite you to celebrate this continued collaboration
													with us as we look forward to a future filled with
													growth, innovation, and shared triumphs!
												</p>
											</div>
										</div>
										<div className="col-lg-6 col-12">
											<img
												className="w-100 d-lg-block d-md-none mt--30"
												src={poster}
												alt="ISTQB and Armsoft partnership poster"
												data-bs-toggle="modal"
												data-bs-target="#posterModal"
											/>
										</div>
										{/* MODAL START*/}
										<div
											className="modal fade"
											id="posterModal"
											tabIndex="-1"
											aria-hidden="true"
										>
											<div
												style={{
													width: "auto",
													height: "auto",
													maxWidth: "100%",
													maxHeight: "100%",
													display: "block",
													margin: "auto",
												}}
												className="modal-dialog"
											>
												<div className="modal-content">
													<div
														style={{
															width: "auto",
															height: "auto",
															maxWidth: "100%",
															maxHeight: "100%",
															display: "block",
															margin: "auto",
														}}
														className="modal-body"
													>
														<button
															type="button"
															className="btn-close"
															data-bs-dismiss="modal"
															aria-label="Close"
														></button>
														<img
															style={{
																width: "auto",
																height: "auto",
																maxWidth: "100%",
																maxHeight: "100%",
																display: "block",
																margin: "auto",
															}}
															// className="w-100 d-lg-block d-md-none"
															src={poster}
														/>
													</div>
												</div>
											</div>
										</div>
										{/* MODAL END */}
									</div>
									{/* End Single Area */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* End Page Wrapper */}

			{/* Start Back To Top */}
			{showBackToTop && (
				<div className="backto-top" style={{ display: showBackToTop ? "block" : "none" }}>
					<div>
						<div onClick={scrollToTop} className="backto-top">
							<FiChevronUp />
						</div>
					</div>
				</div>
			)}
			{/* End Back To Top */}

			<Footer />
		</>
	);
}
