import Header from "../components/header/HeaderThree";
import { FiChevronUp } from "react-icons/fi";
import Footer from "../components/footer/Footer";
import PageScrollTop from "../components/pageScrollTop/PageScrollTop";

export default function Error404() {
	const { showBackToTop, scrollToTop } = PageScrollTop();

	return (
		<>
			<Header headerPosition="header--transparent" color="color-white" logo="logo-light" />
			{/* Start Page Error  */}
			<div className="error-page-inner bg_color--4">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="inner">
								<h1 className="title theme-gradient">404!</h1>
								<h3 className="sub-title">Page not found!</h3>
								<span>The page you were looking for could not be found.</span>
								<div className="error-button">
									<a className="rn-button-style--2 btn-solid" href="/">
										Back To Homepage
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* End Page Error  */}

			{/* Start Back To Top */}
			{showBackToTop && (
				<div className="backto-top" style={{ display: showBackToTop ? "block" : "none" }}>
					<div>
						<div onClick={scrollToTop} className="backto-top">
							<FiChevronUp />
						</div>
					</div>
				</div>
			)}
			{/* End Back To Top */}

			<Footer />
		</>
	);
}
