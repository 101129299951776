import PageHelmet from "../../components/common/Helmet";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../../components/header/HeaderThree";
import Footer from "../../components/footer/Footer";
import ctttaLogo from "../../assets/images/logos/ctal_tta_logo.png";
import ctttaTable from "../../assets/images/tables/ctal_tta_table.png";
import ExamStructure from "./ExamStructure";
import PageScrollTop from "../../components/pageScrollTop/PageScrollTop";

const businessOutcomesList = [
	"Recognize and classify the typical risks associated with the performance, security, reliability, portability and maintainability of software systems.",
	"Provide technical elements to the planning, design and execution of tests for mitigating performance, security, reliability, portability and maintainability risks.",
	"Select and apply appropriate white-box test techniques to ensure that tests provide an adequate level of confidence, based on design coverage.",
	"Effectively participate in reviews with developers and software architects applying knowledge of typical defects in the code and architecture.",
	"Improve the quality characteristics of code and architecture by making use of different analysis techniques.",
	"Outline the costs and benefits to be expected from introducing particular types of test automation.",
	"Select appropriate tools to automate technical testing tasks.",
	"Understand the technical issues and concepts in applying test automation.",
];

export default function TechnicalTestAnalyst() {
	const { showBackToTop, scrollToTop } = PageScrollTop();

	const showBusinessOutcomes = businessOutcomesList.map((item, index) => {
		return <li key={index}>{item}</li>;
	});

	return (
		<>
			{/* Start Pagehelmet  */}
			<PageHelmet pageTitle="ISTQB Advanced Level Test Manager" />
			{/* End Pagehelmet  */}

			<Header />

			{/* Start Breadcrump Area */}
			<div
				className="breadcrumb-area rn-bg-color pt--20 pb--60 bg_image bg_image--certifications"
				data-black-overlay="6"
			>
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="rn-page-title text-center pt--100">
								<h2 className="title theme-gradient">Advanced Level Test Analyst</h2>
								<p>Certified Tester Advanced Level Test Analyst (CTAL-TA)</p>
								<div className="portfolio-button">
									<a
										className="rn-button-style--2 btn-solid-register"
										href="http://bit.ly/3jatMF3"
										target="_blank"
									>
										Register Now
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* End Breadcrump Area */}

			{/* Start Page Wrapper */}
			<div className="rn-service-details ptb--30 bg_color--1">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="service-details-inner">
								<div className="inner">
									{/* Start Single Area */}
									<div className="row sercice-details-content pb--20 align-items-center">
										<div className="col-lg-10 col-12">
											<div className="details mt_md--30 mt_sm--30">
												<h4 className="title">Overview</h4>
												<p>
													The ISTQB® Advanced Level Technical Test
													Analyst (CTAL-TTA) provides a thorough
													introduction to the technical testing skills
													which are fundamental in many organizations
													today. These skills include risk-based
													testing, white box testing, static and
													dynamic analysis, non-functional testing, and
													test automation.
												</p>

												<h4 className="title">Audience</h4>
												<p>
													The Advanced Level Technical Test Analyst
													certification is suitable for anyone who is
													involved in testing as well as anyone
													interested in further developing their
													software testing knowledge. This includes
													people performing activities such as test
													analysis, test consulting, and software
													development.
													<br />
													To gain this certification, candidates must
													hold the{" "}
													<a href="/certifications/certified-tester-foundation-level">
														<b>
															Certified Tester Foundation Level{" "}
														</b>
													</a>
													certificate and have sufficient practical
													experience. Please contact an ISTQB® Member
													Board or Exam Provider to determine the
													specific practical experience criteria.
												</p>
											</div>
										</div>
										<div className="col-lg-2 col-4">
											<div className="thumb">
												<img
													className="w-100 d-lg-block d-md-none"
													src={ctttaLogo}
													alt="CTTTA Logo"
													data-bs-toggle="modal"
													data-bs-target="#ctttaLogoModal"
												/>
											</div>
										</div>
										{/* MODAL START*/}
										<div
											className="modal fade"
											id="ctttaLogoModal"
											tabIndex="-1"
											aria-hidden="true"
										>
											<div className="modal-dialog">
												<div className="modal-content">
													<div className="modal-body">
														<button
															type="button"
															className="btn-close"
															data-bs-dismiss="modal"
															aria-label="Close"
														></button>
														<img
															className="w-100 d-lg-block d-md-none"
															src={ctttaLogo}
														/>
													</div>
												</div>
											</div>
										</div>
										{/* MODAL END*/}
									</div>
									{/* End Single Area */}

									{/* Start Single Area */}
									<div className="row sercice-details-content pb--20 align-items-center">
										<div className="col-lg- col-12 order-2 order-lg-1">
											<div className="details mt_md--30 mt_sm--30">
												<h4 className="title">Contents</h4>
												<div
													className="thumb"
													style={{ paddingTop: "13px" }}
												>
													<img
														className="w-100"
														src={ctttaTable}
														alt="CTTTA Content Table"
													/>
												</div>
											</div>
										</div>
									</div>
									{/* End Single Area */}

									{/* Start Single Area */}
									<div className="row sercice-details-content pb--20 align-items-center">
										<div className="col-lg-12 col-10 order-2 order-lg-4">
											<div className="details mt_md--30 mt_sm--30">
												<h4 className="title">Exam Structure</h4>
												<ExamStructure exam="ctal-tta" />
											</div>
										</div>
									</div>
									{/* End Single Area */}

									{/* Start Single Area */}
									<div className="row sercice-details-content pb--20 align-items-center">
										<div className="col-lg- col-12 order-2 order-lg-1">
											<div className="details mt_md--30 mt_sm--30">
												<h4 className="title">Business Outcomes</h4>
												<p>
													A candidate who has achieved the Advanced
													Level Technical Test Analyst certification
													should be able to:
												</p>
												<ul className="liststyle">
													{showBusinessOutcomes}
												</ul>
											</div>
										</div>
									</div>
									{/* End Single Area */}

									{/* Start Single Area */}
									<div className="row sercice-details-content pb--20 align-items-center">
										<div className="col-lg- col-12 order-2 order-lg-1">
											<div className="details mt_md--30 mt_sm--30">
												<h4 className="title">More Information</h4>
												<p>
													Training is available from Accredited
													Training Providers (classroom, virtual, and
													e-learning). We highly recommend attending
													accredited training as it ensures that an
													ISTQB® Member Board has assessed the
													materials for relevance and consistency
													against the syllabus.
													<br />
													Self-study, using the syllabus and
													recommended reading material, is also an
													option when preparing for the exam.
													<br />
													<b>
														Holders of this certification may choose
														to proceed to other Core, Agile, or
														Specialist stream certifications.
													</b>
												</p>
											</div>
										</div>
									</div>
									{/* End Single Area */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* End Page Wrapper */}

			{/* Start Back To Top */}
			{showBackToTop && (
				<div className="backto-top" style={{ display: showBackToTop ? "block" : "none" }}>
					<div>
						<div onClick={scrollToTop} className="backto-top">
							<FiChevronUp />
						</div>
					</div>
				</div>
			)}
			{/* End Back To Top */}

			<Footer />
		</>
	);
}
