import { GrCircleQuestion } from "react-icons/gr";

export default function ExamStructure({ exam }) {
	if (exam === "ctfl3.1") {
		return (
			<div
				className="col-lg-12 col-10"
				style={{
					flexWrap: "wrap",
					display: "flex",
					justifyContent: "space-between",
				}}
			>
				<div
					style={{
						display: "flex",
						alignItems: "flex-start",
					}}
				>
					<span
						style={{
							fontSize: "20px",
							marginRight: "6px",
						}}
					>
						<GrCircleQuestion />
					</span>
					<span
						style={{
							fontSize: "18px",
						}}
					>
						No. of Questions:{" "}
						<span
							style={{
								fontWeight: "bold",
							}}
						>
							40
						</span>
					</span>
				</div>
				<div
					style={{
						display: "flex",
						alignItems: "flex-start",
					}}
				>
					<span
						style={{
							fontSize: "20px",
							marginRight: "6px",
						}}
					>
						<GrCircleQuestion />
					</span>
					<span
						style={{
							fontSize: "18px",
						}}
					>
						Total Points:{" "}
						<span
							style={{
								fontWeight: "bold",
							}}
						>
							40
						</span>
					</span>
				</div>
				<div
					style={{
						display: "flex",
						alignItems: "flex-start",
					}}
				>
					<span
						style={{
							fontSize: "20px",
							marginRight: "6px",
						}}
					>
						<GrCircleQuestion />
					</span>
					<span
						style={{
							fontSize: "18px",
						}}
					>
						Pasing Score:{" "}
						<span
							style={{
								fontWeight: "bold",
							}}
						>
							26
						</span>
					</span>
				</div>
				<div
					style={{
						display: "flex",
						alignItems: "flex-start",
					}}
				>
					<span
						style={{
							fontSize: "20px",
							marginRight: "6px",
						}}
					>
						<GrCircleQuestion />
					</span>
					<span
						style={{
							fontSize: "18px",
						}}
					>
						Exam length (mins):{" "}
						<span
							style={{
								fontWeight: "bold",
								paddingRight: "5px",
							}}
						>
							60
						</span>
						(+25% Non-Native Language)
					</span>
				</div>
			</div>
		);
	} else if (exam === "ctfl4") {
		return (
			<div
				className="col-lg-12 col-10"
				style={{
					flexWrap: "wrap",
					display: "flex",
					justifyContent: "space-between",
				}}
			>
				<div
					style={{
						display: "flex",
						alignItems: "flex-start",
					}}
				>
					<span
						style={{
							fontSize: "20px",
							marginRight: "6px",
						}}
					>
						<GrCircleQuestion />
					</span>
					<span
						style={{
							fontSize: "18px",
						}}
					>
						No. of Questions:{" "}
						<span
							style={{
								fontWeight: "bold",
							}}
						>
							40
						</span>
					</span>
				</div>
				<div
					style={{
						display: "flex",
						alignItems: "flex-start",
					}}
				>
					<span
						style={{
							fontSize: "20px",
							marginRight: "6px",
						}}
					>
						<GrCircleQuestion />
					</span>
					<span
						style={{
							fontSize: "18px",
						}}
					>
						Total Points:{" "}
						<span
							style={{
								fontWeight: "bold",
							}}
						>
							40
						</span>
					</span>
				</div>
				<div
					style={{
						display: "flex",
						alignItems: "flex-start",
					}}
				>
					<span
						style={{
							fontSize: "20px",
							marginRight: "6px",
						}}
					>
						<GrCircleQuestion />
					</span>
					<span
						style={{
							fontSize: "18px",
						}}
					>
						Pasing Score:{" "}
						<span
							style={{
								fontWeight: "bold",
							}}
						>
							26
						</span>
					</span>
				</div>
				<div
					style={{
						display: "flex",
						alignItems: "flex-start",
					}}
				>
					<span
						style={{
							fontSize: "20px",
							marginRight: "6px",
						}}
					>
						<GrCircleQuestion />
					</span>
					<span
						style={{
							fontSize: "18px",
						}}
					>
						Exam length (mins):{" "}
						<span
							style={{
								fontWeight: "bold",
								paddingRight: "5px",
							}}
						>
							60
						</span>
						(+25% Non-Native Language)
					</span>
				</div>
			</div>
		);
	} else if (exam === "ctat") {
		return (
			<div
				className="col-lg-12 col-10"
				style={{
					flexWrap: "wrap",
					display: "flex",
					justifyContent: "space-between",
				}}
			>
				<div
					style={{
						display: "flex",
						alignItems: "flex-start",
					}}
				>
					<span
						style={{
							fontSize: "20px",
							marginRight: "6px",
						}}
					>
						<GrCircleQuestion />
					</span>
					<span
						style={{
							fontSize: "18px",
						}}
					>
						No. of Questions:{" "}
						<span
							style={{
								fontWeight: "bold",
							}}
						>
							40
						</span>
					</span>
				</div>
				<div
					style={{
						display: "flex",
						alignItems: "flex-start",
					}}
				>
					<span
						style={{
							fontSize: "20px",
							marginRight: "6px",
						}}
					>
						<GrCircleQuestion />
					</span>
					<span
						style={{
							fontSize: "18px",
						}}
					>
						Total Points:{" "}
						<span
							style={{
								fontWeight: "bold",
							}}
						>
							40
						</span>
					</span>
				</div>
				<div
					style={{
						display: "flex",
						alignItems: "flex-start",
					}}
				>
					<span
						style={{
							fontSize: "20px",
							marginRight: "6px",
						}}
					>
						<GrCircleQuestion />
					</span>
					<span
						style={{
							fontSize: "18px",
						}}
					>
						Pasing Score:{" "}
						<span
							style={{
								fontWeight: "bold",
							}}
						>
							26
						</span>
					</span>
				</div>
				<div
					style={{
						display: "flex",
						alignItems: "flex-start",
					}}
				>
					<span
						style={{
							fontSize: "20px",
							marginRight: "6px",
						}}
					>
						<GrCircleQuestion />
					</span>
					<span
						style={{
							fontSize: "18px",
						}}
					>
						Exam length (mins):{" "}
						<span
							style={{
								fontWeight: "bold",
								paddingRight: "5px",
							}}
						>
							60
						</span>
						(+25% Non-Native Language)
					</span>
				</div>
			</div>
		);
	} else if (exam === "ctal-tm") {
		return (
			<div
				className="col-lg-12 col-10"
				style={{
					flexWrap: "wrap",
					display: "flex",
					justifyContent: "space-between",
				}}
			>
				<div
					style={{
						display: "flex",
						alignItems: "flex-start",
					}}
				>
					<span
						style={{
							fontSize: "20px",
							marginRight: "6px",
						}}
					>
						<GrCircleQuestion />
					</span>
					<span
						style={{
							fontSize: "18px",
						}}
					>
						No. of Questions:{" "}
						<span
							style={{
								fontWeight: "bold",
							}}
						>
							65
						</span>
					</span>
				</div>
				<div
					style={{
						display: "flex",
						alignItems: "flex-start",
					}}
				>
					<span
						style={{
							fontSize: "20px",
							marginRight: "6px",
						}}
					>
						<GrCircleQuestion />
					</span>
					<span
						style={{
							fontSize: "18px",
						}}
					>
						Total Points:{" "}
						<span
							style={{
								fontWeight: "bold",
							}}
						>
							115
						</span>
					</span>
				</div>
				<div
					style={{
						display: "flex",
						alignItems: "flex-start",
					}}
				>
					<span
						style={{
							fontSize: "20px",
							marginRight: "6px",
						}}
					>
						<GrCircleQuestion />
					</span>
					<span
						style={{
							fontSize: "18px",
						}}
					>
						Pasing Score:{" "}
						<span
							style={{
								fontWeight: "bold",
							}}
						>
							75
						</span>
					</span>
				</div>
				<div
					style={{
						display: "flex",
						alignItems: "flex-start",
					}}
				>
					<span
						style={{
							fontSize: "20px",
							marginRight: "6px",
						}}
					>
						<GrCircleQuestion />
					</span>
					<span
						style={{
							fontSize: "18px",
						}}
					>
						Exam length (mins):{" "}
						<span
							style={{
								fontWeight: "bold",
								paddingRight: "5px",
							}}
						>
							180
						</span>
						(+25% Non-Native Language)
					</span>
				</div>
			</div>
		);
	} else if (exam === "ctal-tm3") {
		return (
			<div
				className="col-lg-12 col-10"
				style={{
					flexWrap: "wrap",
					display: "flex",
					justifyContent: "space-between",
				}}
			>
				<div
					style={{
						display: "flex",
						alignItems: "flex-start",
					}}
				>
					<span
						style={{
							fontSize: "20px",
							marginRight: "6px",
						}}
					>
						<GrCircleQuestion />
					</span>
					<span
						style={{
							fontSize: "18px",
						}}
					>
						No. of Questions:{" "}
						<span
							style={{
								fontWeight: "bold",
							}}
						>
							50
						</span>
					</span>
				</div>
				<div
					style={{
						display: "flex",
						alignItems: "flex-start",
					}}
				>
					<span
						style={{
							fontSize: "20px",
							marginRight: "6px",
						}}
					>
						<GrCircleQuestion />
					</span>
					<span
						style={{
							fontSize: "18px",
						}}
					>
						Total Points:{" "}
						<span
							style={{
								fontWeight: "bold",
							}}
						>
							88
						</span>
					</span>
				</div>
				<div
					style={{
						display: "flex",
						alignItems: "flex-start",
					}}
				>
					<span
						style={{
							fontSize: "20px",
							marginRight: "6px",
						}}
					>
						<GrCircleQuestion />
					</span>
					<span
						style={{
							fontSize: "18px",
						}}
					>
						Pasing Score:{" "}
						<span
							style={{
								fontWeight: "bold",
							}}
						>
							58
						</span>
					</span>
				</div>
				<div
					style={{
						display: "flex",
						alignItems: "flex-start",
					}}
				>
					<span
						style={{
							fontSize: "20px",
							marginRight: "6px",
						}}
					>
						<GrCircleQuestion />
					</span>
					<span
						style={{
							fontSize: "18px",
						}}
					>
						Exam length (mins):{" "}
						<span
							style={{
								fontWeight: "bold",
								paddingRight: "5px",
							}}
						>
							120
						</span>
						(+25% Non-Native Language)
					</span>
				</div>
			</div>
		);
	} else if (exam === "ctal-ta") {
		return (
			<div
				className="col-lg-12 col-10"
				style={{
					flexWrap: "wrap",
					display: "flex",
					justifyContent: "space-between",
				}}
			>
				<div
					style={{
						display: "flex",
						alignItems: "flex-start",
					}}
				>
					<span
						style={{
							fontSize: "20px",
							marginRight: "6px",
						}}
					>
						<GrCircleQuestion />
					</span>
					<span
						style={{
							fontSize: "18px",
						}}
					>
						No. of Questions:{" "}
						<span
							style={{
								fontWeight: "bold",
							}}
						>
							40
						</span>
					</span>
				</div>
				<div
					style={{
						display: "flex",
						alignItems: "flex-start",
					}}
				>
					<span
						style={{
							fontSize: "20px",
							marginRight: "6px",
						}}
					>
						<GrCircleQuestion />
					</span>
					<span
						style={{
							fontSize: "18px",
						}}
					>
						Total Points:{" "}
						<span
							style={{
								fontWeight: "bold",
							}}
						>
							80
						</span>
					</span>
				</div>
				<div
					style={{
						display: "flex",
						alignItems: "flex-start",
					}}
				>
					<span
						style={{
							fontSize: "20px",
							marginRight: "6px",
						}}
					>
						<GrCircleQuestion />
					</span>
					<span
						style={{
							fontSize: "18px",
						}}
					>
						Pasing Score:{" "}
						<span
							style={{
								fontWeight: "bold",
							}}
						>
							52
						</span>
					</span>
				</div>
				<div
					style={{
						display: "flex",
						alignItems: "flex-start",
					}}
				>
					<span
						style={{
							fontSize: "20px",
							marginRight: "6px",
						}}
					>
						<GrCircleQuestion />
					</span>
					<span
						style={{
							fontSize: "18px",
						}}
					>
						Exam length (mins):{" "}
						<span
							style={{
								fontWeight: "bold",
								paddingRight: "5px",
							}}
						>
							120
						</span>
						(+25% Non-Native Language)
					</span>
				</div>
			</div>
		);
	} else if (exam === "ctal-tta") {
		return (
			<div
				className="col-lg-12 col-10"
				style={{
					flexWrap: "wrap",
					display: "flex",
					justifyContent: "space-between",
				}}
			>
				<div
					style={{
						display: "flex",
						alignItems: "flex-start",
					}}
				>
					<span
						style={{
							fontSize: "20px",
							marginRight: "6px",
						}}
					>
						<GrCircleQuestion />
					</span>
					<span
						style={{
							fontSize: "18px",
						}}
					>
						No. of Questions:{" "}
						<span
							style={{
								fontWeight: "bold",
							}}
						>
							45
						</span>
					</span>
				</div>
				<div
					style={{
						display: "flex",
						alignItems: "flex-start",
					}}
				>
					<span
						style={{
							fontSize: "20px",
							marginRight: "6px",
						}}
					>
						<GrCircleQuestion />
					</span>
					<span
						style={{
							fontSize: "18px",
						}}
					>
						Total Points:{" "}
						<span
							style={{
								fontWeight: "bold",
							}}
						>
							78
						</span>
					</span>
				</div>
				<div
					style={{
						display: "flex",
						alignItems: "flex-start",
					}}
				>
					<span
						style={{
							fontSize: "20px",
							marginRight: "6px",
						}}
					>
						<GrCircleQuestion />
					</span>
					<span
						style={{
							fontSize: "18px",
						}}
					>
						Pasing Score:{" "}
						<span
							style={{
								fontWeight: "bold",
							}}
						>
							51
						</span>
					</span>
				</div>
				<div
					style={{
						display: "flex",
						alignItems: "flex-start",
					}}
				>
					<span
						style={{
							fontSize: "20px",
							marginRight: "6px",
						}}
					>
						<GrCircleQuestion />
					</span>
					<span
						style={{
							fontSize: "18px",
						}}
					>
						Exam length (mins):{" "}
						<span
							style={{
								fontWeight: "bold",
								paddingRight: "5px",
							}}
						>
							120
						</span>
						(+25% Non-Native Language)
					</span>
				</div>
			</div>
		);
	} else if (exam === "ct-ai") {
		return (
			<div
				className="col-lg-12 col-10"
				style={{
					flexWrap: "wrap",
					display: "flex",
					justifyContent: "space-between",
				}}
			>
				<div
					style={{
						display: "flex",
						alignItems: "flex-start",
					}}
				>
					<span
						style={{
							fontSize: "20px",
							marginRight: "6px",
						}}
					>
						<GrCircleQuestion />
					</span>
					<span
						style={{
							fontSize: "18px",
						}}
					>
						No. of Questions:{" "}
						<span
							style={{
								fontWeight: "bold",
							}}
						>
							40
						</span>
					</span>
				</div>
				<div
					style={{
						display: "flex",
						alignItems: "flex-start",
					}}
				>
					<span
						style={{
							fontSize: "20px",
							marginRight: "6px",
						}}
					>
						<GrCircleQuestion />
					</span>
					<span
						style={{
							fontSize: "18px",
						}}
					>
						Total Points:{" "}
						<span
							style={{
								fontWeight: "bold",
							}}
						>
							47
						</span>
					</span>
				</div>
				<div
					style={{
						display: "flex",
						alignItems: "flex-start",
					}}
				>
					<span
						style={{
							fontSize: "20px",
							marginRight: "6px",
						}}
					>
						<GrCircleQuestion />
					</span>
					<span
						style={{
							fontSize: "18px",
						}}
					>
						Pasing Score:{" "}
						<span
							style={{
								fontWeight: "bold",
							}}
						>
							31
						</span>
					</span>
				</div>
				<div
					style={{
						display: "flex",
						alignItems: "flex-start",
					}}
				>
					<span
						style={{
							fontSize: "20px",
							marginRight: "6px",
						}}
					>
						<GrCircleQuestion />
					</span>
					<span
						style={{
							fontSize: "18px",
						}}
					>
						Exam length (mins):{" "}
						<span
							style={{
								fontWeight: "bold",
								paddingRight: "5px",
							}}
						>
							60
						</span>
						(+25% Non-Native Language)
					</span>
				</div>
			</div>
		);
	} else if (exam === "ct-gt") {
		return (
			<div
				className="col-lg-12 col-10"
				style={{
					flexWrap: "wrap",
					display: "flex",
					justifyContent: "space-between",
				}}
			>
				<div
					style={{
						display: "flex",
						alignItems: "flex-start",
					}}
				>
					<span
						style={{
							fontSize: "20px",
							marginRight: "6px",
						}}
					>
						<GrCircleQuestion />
					</span>
					<span
						style={{
							fontSize: "18px",
						}}
					>
						No. of Questions:{" "}
						<span
							style={{
								fontWeight: "bold",
							}}
						>
							40
						</span>
					</span>
				</div>
				<div
					style={{
						display: "flex",
						alignItems: "flex-start",
					}}
				>
					<span
						style={{
							fontSize: "20px",
							marginRight: "6px",
						}}
					>
						<GrCircleQuestion />
					</span>
					<span
						style={{
							fontSize: "18px",
						}}
					>
						Total Points:{" "}
						<span
							style={{
								fontWeight: "bold",
							}}
						>
							40
						</span>
					</span>
				</div>
				<div
					style={{
						display: "flex",
						alignItems: "flex-start",
					}}
				>
					<span
						style={{
							fontSize: "20px",
							marginRight: "6px",
						}}
					>
						<GrCircleQuestion />
					</span>
					<span
						style={{
							fontSize: "18px",
						}}
					>
						Pasing Score:{" "}
						<span
							style={{
								fontWeight: "bold",
							}}
						>
							26
						</span>
					</span>
				</div>
				<div
					style={{
						display: "flex",
						alignItems: "flex-start",
					}}
				>
					<span
						style={{
							fontSize: "20px",
							marginRight: "6px",
						}}
					>
						<GrCircleQuestion />
					</span>
					<span
						style={{
							fontSize: "18px",
						}}
					>
						Exam length (mins):{" "}
						<span
							style={{
								fontWeight: "bold",
								paddingRight: "5px",
							}}
						>
							60
						</span>
						(+25% Non-Native Language)
					</span>
				</div>
			</div>
		);
	} else if (exam === "ct-mat") {
		return (
			<div
				className="col-lg-12 col-10"
				style={{
					flexWrap: "wrap",
					display: "flex",
					justifyContent: "space-between",
				}}
			>
				<div
					style={{
						display: "flex",
						alignItems: "flex-start",
					}}
				>
					<span
						style={{
							fontSize: "20px",
							marginRight: "6px",
						}}
					>
						<GrCircleQuestion />
					</span>
					<span
						style={{
							fontSize: "18px",
						}}
					>
						No. of Questions:{" "}
						<span
							style={{
								fontWeight: "bold",
							}}
						>
							40
						</span>
					</span>
				</div>
				<div
					style={{
						display: "flex",
						alignItems: "flex-start",
					}}
				>
					<span
						style={{
							fontSize: "20px",
							marginRight: "6px",
						}}
					>
						<GrCircleQuestion />
					</span>
					<span
						style={{
							fontSize: "18px",
						}}
					>
						Total Points:{" "}
						<span
							style={{
								fontWeight: "bold",
							}}
						>
							40
						</span>
					</span>
				</div>
				<div
					style={{
						display: "flex",
						alignItems: "flex-start",
					}}
				>
					<span
						style={{
							fontSize: "20px",
							marginRight: "6px",
						}}
					>
						<GrCircleQuestion />
					</span>
					<span
						style={{
							fontSize: "18px",
						}}
					>
						Pasing Score:{" "}
						<span
							style={{
								fontWeight: "bold",
							}}
						>
							26
						</span>
					</span>
				</div>
				<div
					style={{
						display: "flex",
						alignItems: "flex-start",
					}}
				>
					<span
						style={{
							fontSize: "20px",
							marginRight: "6px",
						}}
					>
						<GrCircleQuestion />
					</span>
					<span
						style={{
							fontSize: "18px",
						}}
					>
						Exam length (mins):{" "}
						<span
							style={{
								fontWeight: "bold",
								paddingRight: "5px",
							}}
						>
							60
						</span>
						(+25% Non-Native Language)
					</span>
				</div>
			</div>
		);
	} else if (exam === "ctal-sec") {
		return (
			<div
				className="col-lg-12 col-10"
				style={{
					flexWrap: "wrap",
					display: "flex",
					justifyContent: "space-between",
				}}
			>
				<div
					style={{
						display: "flex",
						alignItems: "flex-start",
					}}
				>
					<span
						style={{
							fontSize: "20px",
							marginRight: "6px",
						}}
					>
						<GrCircleQuestion />
					</span>
					<span
						style={{
							fontSize: "18px",
						}}
					>
						No. of Questions:{" "}
						<span
							style={{
								fontWeight: "bold",
							}}
						>
							45
						</span>
					</span>
				</div>
				<div
					style={{
						display: "flex",
						alignItems: "flex-start",
					}}
				>
					<span
						style={{
							fontSize: "20px",
							marginRight: "6px",
						}}
					>
						<GrCircleQuestion />
					</span>
					<span
						style={{
							fontSize: "18px",
						}}
					>
						Total Points:{" "}
						<span
							style={{
								fontWeight: "bold",
							}}
						>
							80
						</span>
					</span>
				</div>
				<div
					style={{
						display: "flex",
						alignItems: "flex-start",
					}}
				>
					<span
						style={{
							fontSize: "20px",
							marginRight: "6px",
						}}
					>
						<GrCircleQuestion />
					</span>
					<span
						style={{
							fontSize: "18px",
						}}
					>
						Pasing Score:{" "}
						<span
							style={{
								fontWeight: "bold",
							}}
						>
							52
						</span>
					</span>
				</div>
				<div
					style={{
						display: "flex",
						alignItems: "flex-start",
					}}
				>
					<span
						style={{
							fontSize: "20px",
							marginRight: "6px",
						}}
					>
						<GrCircleQuestion />
					</span>
					<span
						style={{
							fontSize: "18px",
						}}
					>
						Exam length (mins):{" "}
						<span
							style={{
								fontWeight: "bold",
								paddingRight: "5px",
							}}
						>
							120
						</span>
						(+25% Non-Native Language)
					</span>
				</div>
			</div>
		);
	} else if (exam === "ctal-tae") {
		return (
			<div
				className="col-lg-12 col-10"
				style={{
					flexWrap: "wrap",
					display: "flex",
					justifyContent: "space-between",
				}}
			>
				<div
					style={{
						display: "flex",
						alignItems: "flex-start",
					}}
				>
					<span
						style={{
							fontSize: "20px",
							marginRight: "6px",
						}}
					>
						<GrCircleQuestion />
					</span>
					<span
						style={{
							fontSize: "18px",
						}}
					>
						No. of Questions:{" "}
						<span
							style={{
								fontWeight: "bold",
							}}
						>
							40
						</span>
					</span>
				</div>
				<div
					style={{
						display: "flex",
						alignItems: "flex-start",
					}}
				>
					<span
						style={{
							fontSize: "20px",
							marginRight: "6px",
						}}
					>
						<GrCircleQuestion />
					</span>
					<span
						style={{
							fontSize: "18px",
						}}
					>
						Total Points:{" "}
						<span
							style={{
								fontWeight: "bold",
							}}
						>
							75
						</span>
					</span>
				</div>
				<div
					style={{
						display: "flex",
						alignItems: "flex-start",
					}}
				>
					<span
						style={{
							fontSize: "20px",
							marginRight: "6px",
						}}
					>
						<GrCircleQuestion />
					</span>
					<span
						style={{
							fontSize: "18px",
						}}
					>
						Pasing Score:{" "}
						<span
							style={{
								fontWeight: "bold",
							}}
						>
							49
						</span>
					</span>
				</div>
				<div
					style={{
						display: "flex",
						alignItems: "flex-start",
					}}
				>
					<span
						style={{
							fontSize: "20px",
							marginRight: "6px",
						}}
					>
						<GrCircleQuestion />
					</span>
					<span
						style={{
							fontSize: "18px",
						}}
					>
						Exam length (mins):{" "}
						<span
							style={{
								fontWeight: "bold",
								paddingRight: "5px",
							}}
						>
							90
						</span>
						(+25% Non-Native Language)
					</span>
				</div>
			</div>
		);
	} else if (exam === "ctal-tae2") {
		return (
			<div
				className="col-lg-12 col-10"
				style={{
					flexWrap: "wrap",
					display: "flex",
					justifyContent: "space-between",
				}}
			>
				<div
					style={{
						display: "flex",
						alignItems: "flex-start",
					}}
				>
					<span
						style={{
							fontSize: "20px",
							marginRight: "6px",
						}}
					>
						<GrCircleQuestion />
					</span>
					<span
						style={{
							fontSize: "18px",
						}}
					>
						No. of Questions:{" "}
						<span
							style={{
								fontWeight: "bold",
							}}
						>
							40
						</span>
					</span>
				</div>
				<div
					style={{
						display: "flex",
						alignItems: "flex-start",
					}}
				>
					<span
						style={{
							fontSize: "20px",
							marginRight: "6px",
						}}
					>
						<GrCircleQuestion />
					</span>
					<span
						style={{
							fontSize: "18px",
						}}
					>
						Total Points:{" "}
						<span
							style={{
								fontWeight: "bold",
							}}
						>
							66
						</span>
					</span>
				</div>
				<div
					style={{
						display: "flex",
						alignItems: "flex-start",
					}}
				>
					<span
						style={{
							fontSize: "20px",
							marginRight: "6px",
						}}
					>
						<GrCircleQuestion />
					</span>
					<span
						style={{
							fontSize: "18px",
						}}
					>
						Pasing Score:{" "}
						<span
							style={{
								fontWeight: "bold",
							}}
						>
							43
						</span>
					</span>
				</div>
				<div
					style={{
						display: "flex",
						alignItems: "flex-start",
					}}
				>
					<span
						style={{
							fontSize: "20px",
							marginRight: "6px",
						}}
					>
						<GrCircleQuestion />
					</span>
					<span
						style={{
							fontSize: "18px",
						}}
					>
						Exam length (mins):{" "}
						<span
							style={{
								fontWeight: "bold",
								paddingRight: "5px",
							}}
						>
							90
						</span>
						(+25% Non-Native Language)
					</span>
				</div>
			</div>
		);
	}
}
