import PageHelmet from "../../../components/common/Helmet";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../../../components/header/HeaderThree";
import Footer from "../../../components/footer/Footer";
import testersDayParty from "../../../assets/images/events/events_09_08_2024/testers-day-party.jpg";
import PageScrollTop from "../../../components/pageScrollTop/PageScrollTop";

export default function TestersDayPartyEvent() {
	const { showBackToTop, scrollToTop } = PageScrollTop();

	return (
		<>
			{/* Start Pagehelmet  */}
			<PageHelmet pageTitle="QA Summer Camp" />
			{/* End Pagehelmet  */}

			<Header />

			{/* Start Breadcrump Area */}
			<div className="rn-page-title-area pt--20 pb--60" data-black-overlay="7">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="rn-page-title text-center pt--100">
								<h2 className="title theme-gradient">Testers Day Party</h2>
								<p>09/08/2024</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* End Breadcrump Area */}

			{/* Start Page Wrapper */}
			<div className="rn-service-details ptb--30 bg_color--1">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="service-details-inner">
								<div className="inner">
									{/* Start Single Area */}
									<div className="row sercice-details-content pb--20 align-items-center">
										<div className="col-lg-6 col-12">
											<div className="details mt_md--30 mt_sm--30">
												<h4 className="title">Overview</h4>
												<p>Let's Celebrate!</p>
												<p>
													We're excited to announce our next big event,
													a perfectly timed PARTY to celebrate Tester's
													Day! Join us for an unforgettable rooftop
													party on September 7th, featuring networking,
													fun, dancing, and stunning views!
													<br />
													What are you waiting for? Tester's Day Party
													tickets are available now!!!
												</p>
												<p>
													Join us for a fantastic event at C.View Yvn
													Club! This event is the perfect opportunity
													to network and have a blast!
												</p>
												<p>
													Your ticket will include:
													<br />
													Two alcoholic drinks of your choice from the
													party menu.
													<br />
													Finger-food buffet.
													<br />
													A special photobooth.
													<br />
													Unlimited dancing.
													<br />A fantastic view from the rooftop.
												</p>
												<p>
													Early Bird ticket price: 12,000 AMD
													<br />
													The price from 01 September: 15,000 AMD
													<br />
													Ticket price at the door: 18,000 AMD
												</p>
												<p>
													Register{" "}
													<a
														href="https://buytickets.at/armstqb/1353444"
														target="_blank"
													>
														here
													</a>{" "}
													and we will contact you for your tickets.
												</p>
											</div>
										</div>
										<div className="col-lg-6 col-12">
											<img
												className="w-100 d-lg-block d-md-none mt--30"
												src={testersDayParty}
												alt="Testers Day Party Poster"
											/>
										</div>
									</div>
									{/* End Single Area */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* End Page Wrapper */}

			{/* Start Back To Top */}
			{showBackToTop && (
				<div className="backto-top" style={{ display: showBackToTop ? "block" : "none" }}>
					<div>
						<div onClick={scrollToTop} className="backto-top">
							<FiChevronUp />
						</div>
					</div>
				</div>
			)}
			{/* End Back To Top */}

			<Footer />
		</>
	);
}
