import PageHelmet from "../../../components/common/Helmet";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../../../components/header/HeaderThree";
import Footer from "../../../components/footer/Footer";
import FirstNewsGallery from "./FirstNews_Gallery";
import PageScrollTop from "../../../components/pageScrollTop/PageScrollTop";

export default function VoloNews() {
	const { showBackToTop, scrollToTop } = PageScrollTop();

	return (
		<>
			{/* Start Pagehelmet  */}
			<PageHelmet pageTitle="ISTQB Silver Partnership with Volo" />
			{/* End Pagehelmet  */}

			<Header />

			{/* Start Breadcrump Area */}
			<div className="rn-page-title-area pt--20 pb--60" data-black-overlay="7">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="rn-page-title text-center pt--100">
								<h2 className="title theme-gradient">
									ISTQB Silver Partnership with Volo
								</h2>
								<p>04/12/2023</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* End Breadcrump Area */}

			{/* Start Page Wrapper */}
			<div className="rn-service-details ptb--30 bg_color--1">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="service-details-inner">
								<div className="inner">
									{/* Start Single Area */}
									<div className="row sercice-details-content pb--20 align-items-center">
										<div className="col-lg-12 col-12">
											<div className="details mt_md--30 mt_sm--30">
												<h4 className="title">Overview</h4>
												<p>
													It was a great pleasure to be hosted by VOLO
													Company as they achieved ISTQB Silver
													Partnership status! The certificate
													presentation was a delightful event, filled
													with insightful discussions and shared
													passion for advancing software testing.
												</p>
												<p>
													A sincere thanks to VOLO Company for their
													warm hospitality. May this milestone be a
													stepping stone to even greater success!
												</p>

												<h4 className="title">Gallery</h4>
												<FirstNewsGallery column="col-lg-3 col-md-6 col-sm-6 col-12" />
											</div>
										</div>
									</div>
									{/* End Single Area */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* End Page Wrapper */}

			{/* Start Back To Top */}
			{showBackToTop && (
				<div className="backto-top" style={{ display: showBackToTop ? "block" : "none" }}>
					<div>
						<div onClick={scrollToTop} className="backto-top">
							<FiChevronUp />
						</div>
					</div>
				</div>
			)}
			{/* End Back To Top */}

			<Footer />
		</>
	);
}
