import PageHelmet from "../../../components/common/Helmet";
import { FiChevronUp } from "react-icons/fi";
import Header from "../../../components/header/HeaderThree";
import Footer from "../../../components/footer/Footer";
import SummerMeetUpNewsGallery from "./SummerMeetUpNews_Gallery";
import PageScrollTop from "../../../components/pageScrollTop/PageScrollTop";

export default function SummerMeetUpNews() {
	const { showBackToTop, scrollToTop } = PageScrollTop();

	return (
		<>
			{/* Start Pagehelmet  */}
			<PageHelmet pageTitle="Summer Meetup #1: ISTQB Foundation level 4.0" />
			{/* End Pagehelmet  */}

			<Header />

			{/* Start Breadcrump Area */}
			<div className="rn-page-title-area pt--20 pb--60" data-black-overlay="7">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="rn-page-title text-center pt--100">
								<h2 className="title theme-gradient">
									Summer Meetup #1: ISTQB Foundation level 4.0
								</h2>
								<p>07/06/2024</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* End Breadcrump Area */}

			{/* Start Page Wrapper */}
			<div className="rn-service-details ptb--30 bg_color--1">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="service-details-inner">
								<div className="inner">
									{/* Start Single Area */}
									<div className="row sercice-details-content pb--20 align-items-center">
										<div className="col-lg-12 col-12">
											<div className="details mt_md--30 mt_sm--30">
												<h4 className="title">Overview</h4>
												<p>
													We had an incredible time at our event on June 5th, and
													we want to extend our heartfelt thanks to everyone who
													joined us. It was a fantastic evening filled with
													insightful discussions, valuable updates, and great
													networking opportunities.
												</p>
												<p>
													A special thank you to our amazing panelists Arman
													Alaverdyan, Arshak Balyan, Sevak Mkrtchyan , and Albert
													Galstyan for sharing their expertise and insights into
													the world of software testing. Your contributions were
													invaluable and truly made the event memorable.
												</p>
												<p>
													We would also like to express our deepest gratitude to
													our co-founders:
													<br />
													Arshak Balyan, for presenting the new ISTQB CTFL v4.0
													program with such clarity and depth.
													<br />
													Arthur Mkrtchyan, for hosting the meet-up and ensuring
													everything ran smoothly.
													<br />
													Your dedication and hard work are the backbone of
													ArmSTQB, and we are grateful for your leadership and
													vision.
													<br />
													Thank you once again to all our attendees, panelists,
													and co-founders. We look forward to seeing you at our
													next event!
												</p>
												<h4 className="title">Gallery</h4>
												<SummerMeetUpNewsGallery column="col-lg-3 col-md-6 col-sm-6 col-12" />
											</div>
										</div>
									</div>
									{/* End Single Area */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* End Page Wrapper */}

			{/* Start Back To Top */}
			{showBackToTop && (
				<div className="backto-top" style={{ display: showBackToTop ? "block" : "none" }}>
					<div>
						<div onClick={scrollToTop} className="backto-top">
							<FiChevronUp />
						</div>
					</div>
				</div>
			)}
			{/* End Back To Top */}

			<Footer />
		</>
	);
}
