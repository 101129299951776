export default function Breadcrumb({ title, page }) {
	if (page === "about-istqb") {
		return (
			<>
				<div
					className="breadcrumb-area rn-bg-color ptb--120 bg_image bg_image--about-istqb"
					data-black-overlay="6"
				>
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="breadcrumb-inner pt--100">
									<h2 className="title">{title}</h2>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	} else if (page === "certifications") {
		return (
			<>
				<div
					className="breadcrumb-area rn-bg-color ptb--120 bg_image bg_image--certifications"
					data-black-overlay="6"
				>
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="breadcrumb-inner pt--100">
									<h2 className="title">{title}</h2>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	} else if (page === "partners") {
		return (
			<>
				<div
					className="breadcrumb-area rn-bg-color ptb--120 bg_image bg_image--partners"
					data-black-overlay="6"
				>
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="breadcrumb-inner pt--100">
									<h2 className="title">{title}</h2>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	} else if (page === "events") {
		return (
			<>
				<div
					className="breadcrumb-area rn-bg-color ptb--120 bg_image bg_image--events"
					data-black-overlay="6"
				>
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="breadcrumb-inner pt--100">
									<h2 className="title">{title}</h2>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	} else if (page === "news") {
		return (
			<>
				<div
					className="breadcrumb-area rn-bg-color ptb--120 bg_image bg_image--news"
					data-black-overlay="6"
				>
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="breadcrumb-inner pt--100">
									<h2 className="title">{title}</h2>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	} else if (page === "faq") {
		return (
			<>
				<div className="breadcrumb-area rn-bg-color ptb--120 bg_image bg_image--faq" data-black-overlay="6">
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="breadcrumb-inner pt--100">
									<h2 className="title">{title}</h2>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	} else if (page === "contact") {
		return (
			<>
				<div
					className="breadcrumb-area rn-bg-color ptb--120 bg_image bg_image--contact"
					data-black-overlay="6"
				>
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="breadcrumb-inner pt--100">
									<h2 className="title">{title}</h2>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}
