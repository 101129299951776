import { useState } from "react";
import emailjs from "emailjs-com";
import ReCAPTCHA from "react-google-recaptcha";

const Result = () => {
	return (
		<p className="success-message">
			Thank you for your message, it has been successfully sent. We will contact you soon.
		</p>
	);
};

export default function ContactForm({ props }) {
	const [result, setResult] = useState(false);
	const [captchaValue, setCaptchaValue] = useState(null);

	const sendEmail = (e) => {
		e.preventDefault();
		emailjs.sendForm("service_3rhrr9w", "template_ft409xi", e.target, "hl0892ryXZY7J09Xo").then(
			(result) => {
				console.log(result.text);
				window.location.reload();
			},
			(error) => {
				console.log(error.text);
				window.location.reload();
			}
		);
		e.target.reset();
		setResult(true);
	};

	setTimeout(() => {
		setResult(false);
	}, 5000);

	return (
		<form action="" onSubmit={sendEmail}>
			<div className="rn-form-group">
				<input type="text" name="from_name" placeholder="Your Name *" required />
			</div>

			<div className="rn-form-group">
				<input type="email" name="email" placeholder="Your Email *" required />
			</div>

			<div className="rn-form-group">
				<input type="text" name="phone" placeholder="Phone Number *" required />
			</div>

			<div className="rn-form-group">
				<input type="text" name="subject" placeholder="Subject *" required />
			</div>

			<div className="rn-form-group">
				<textarea name="message" placeholder="Your Message *" required></textarea>
			</div>

			<ReCAPTCHA
				sitekey="6Le4XHkpAAAAAJp_FztiYAmfVRzjtdfum_yfP-Wz"
				onChange={(val) => setCaptchaValue(val)}
			/>

			<div className="rn-form-group mt--20">
				<button
					className="rn-button-style--2 btn-solid"
					disabled={!captchaValue}
					type="submit"
					value="submit"
					name="submit"
					id="mc-embedded-subscribe"
				>
					Submit Now
				</button>
			</div>

			<div className="rn-form-group">{result ? <Result /> : null}</div>
		</form>
	);
}
