import Header from "../components/header/HeaderThree";
import Footer from "../components/footer/Footer";
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../components/common/Helmet";
import Breadcrumb from "../components/common/Breadcrumb";
import EventsList from "../sub-pages/events/EventsList";
import PageScrollTop from "../components/pageScrollTop/PageScrollTop";

export default function Events() {
	const { showBackToTop, scrollToTop } = PageScrollTop();

	return (
		<>
			<Helmet pageTitle="Events" />
			<Header />

			{/* Start Breadcrump Area */}
			<Breadcrumb title={"Events"} page={"events"} />
			{/* End Breadcrump Area */}

			{/* Start Service Area  */}
			<div id="service" className="fix">
				<div className="service-area ptb--60 bg_color--1">
					<div className="portfolio-sacousel-inner mb--55 mb_sm--0">
						<div className="container">
							<div className="row">
								<div className="col-lg-12">
									<div className="section-title text-left  service-style--3 mb--30 mb_sm--0">
										<h2
											className="title"
											style={{
												borderBottom: "0.7px solid",
												marginBottom: "20px",
											}}
										>
											Events
										</h2>
										<p>Stay up to date with ArmSTQB's events!</p>
									</div>
								</div>
							</div>
							<div className="row">
								<EventsList
									stylevariation="text-left mt--40"
									column="col-lg-4 col-md-6 col-sm-6 col-12"
									item="6"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* End Service Area  */}

			{/* Start Back To Top */}
			{showBackToTop && (
				<div className="backto-top" style={{ display: showBackToTop ? "block" : "none" }}>
					<div>
						<div onClick={scrollToTop} className="backto-top">
							<FiChevronUp />
						</div>
					</div>
				</div>
			)}
			{/* End Back To Top */}

			{/* Start Footer Style  */}
			<Footer />
			{/* End Footer Style  */}
		</>
	);
}
