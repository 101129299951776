import PageHelmet from "../../../components/common/Helmet";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../../../components/header/HeaderThree";
import Footer from "../../../components/footer/Footer";
import book from "../../../assets/images/news/news_19_01_2024/ctfl_self_study_book.jpg";
import PageScrollTop from "../../../components/pageScrollTop/PageScrollTop";

export default function CTFLBookNews() {
	const { showBackToTop, scrollToTop } = PageScrollTop();

	return (
		<>
			{/* Start Pagehelmet  */}
			<PageHelmet pageTitle="ISTQB® CTFL A Self-Study Guide release" />
			{/* End Pagehelmet  */}

			<Header />

			{/* Start Breadcrump Area */}
			<div className="rn-page-title-area pt--20 pb--60" data-black-overlay="7">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="rn-page-title text-center pt--100">
								<h2 className="title theme-gradient">
									ISTQB® CTFL A Self-Study Guide release
								</h2>
								<p>19/01/2024</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* End Breadcrump Area */}

			{/* Start Page Wrapper */}
			<div className="rn-service-details ptb--30 bg_color--1">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="service-details-inner">
								<div className="inner">
									{/* Start Single Area */}
									<div className="row sercice-details-content pb--20 align-items-center">
										<div className="col-lg-8 col-12">
											<div className="details mt_md--30 mt_sm--30">
												<h4 className="title">Overview</h4>
												<p>
													The "ISTQB® Certified Tester Foundation Level
													A Self-Study Guide" book based on ISTQB CTFL
													Syllabus v4.0 is now out!
													<br />A big shoutout to authors Lucjan Stapp,
													Adam Roman, and Michaël Pilaeten for their
													amazing work! Get your copy and delve into
													the world of Software Testing excellence.
													<br />
													🔗 Details here:{" "}
													<a
														href="https://link.springer.com/book/10.1007/978-3-031-42767-1"
														target="_blank"
													>
														https://link.springer.com/book/10.1007/978-3-031-42767-1
													</a>
												</p>
												<p>
													This international certification is your
													ticket to excellence in Software Testing.
													<br />
													Ready to ace the CTFL v4.0 exam? ArmSTQB has
													got you covered!
												</p>
											</div>
										</div>
										<div className="col-lg-4 col-12">
											<img
												className="w-100 d-lg-block d-md-none mt--30"
												src={book}
												alt="CTFL Book"
											/>
										</div>
									</div>
									{/* End Single Area */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* End Page Wrapper */}

			{/* Start Back To Top */}
			{showBackToTop && (
				<div className="backto-top" style={{ display: showBackToTop ? "block" : "none" }}>
					<div>
						<div onClick={scrollToTop} className="backto-top">
							<FiChevronUp />
						</div>
					</div>
				</div>
			)}
			{/* End Back To Top */}

			<Footer />
		</>
	);
}
