import PageHelmet from "../components/common/Helmet";
import Header from "../components/header/HeaderThree";
import Footer from "../components/footer/Footer";
import { FiChevronUp } from "react-icons/fi";
import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemPanel,
	AccordionItemButton,
} from "react-accessible-accordion";
import Breadcrumb from "../components/common/Breadcrumb";
import PageScrollTop from "../components/pageScrollTop/PageScrollTop";

const FAQList = [
	{
		question: "1. What does ISTQB certification provide to the specialist?",
		answer: "The International Software Testing Qualifications Board (ISTQB) certifications are recognized internationally as the de facto standard for professional competence in the field. The certified professionals validate that their knowledge satisfies the competency requirements established by the certification program. Preparing for and successfully passing the certification examination also enhances the expertise and career prospects of the professional, and also proves the determination and ability to hard work.",
	},
	{
		question: "2. Are the examinations conducted by ArmSTQB international or not?",
		answer: "ArmSTQB is the Armenian National Board of ISTQB. ArmSTQB represents ISTQB in Armenia and operates its certification exams. ArmSTQB does not have its independent certification, and any certification issued by ArmSTQB is internationally recognized and conforms to the standards set by the ISTQB certification scheme.",
	},
	{
		question: "3. Are the ISTQB certifications time-bound or indefinite?",
		answer: "ISTQB certifications are indefinite.",
	},
	{
		question: "4. Can I select and undergo any certification examination?",
		answer: "If it's your first time taking an ISTQB certification exam, it must be the ISTQB Certified Tester Foundation Level certification. It serves as a prerequisite for participating in all other certification exams.",
	},
	{
		question: "5. Are the examinations conducted by ArmSTQB in paper-based or computer-based format?",
		answer: "Currently, any ISTQB certification examination conducted by ArmSTQB is carried out in a paper-based format.",
	},
	{
		question: "6. Can I take the exam remotely?",
		answer: "Any ISTQB certification examination conducted by ArmSTQB is taken in the classrooms of ArmSTQB.",
	},
	{
		question:
			"7. Is there a training course that prepares the specialist for the certification examination?",
		answer: "Yes, such a training course does exist. The course program has a 100% coverage of the necessary material for the certification examination. Please, contact ArmSTQB for the details.",
	},
];

export default function FAQ() {
	const { showBackToTop, scrollToTop } = PageScrollTop();

	return (
		<>
			<PageHelmet pageTitle="FAQs" />
			<Header />

			{/* Start Breadcrump Area */}
			<Breadcrumb title={"Frequently Asked Questions"} page={"faq"} />
			{/* End Breadcrump Area */}

			{/* Start Faq Area */}
			<div className="rn-accordion-area pv-feaq-area bg_color--1 ptb--60">
				<div className="container">
					<div className="row row--35">
						<div className="col-lg-12 col-sm-12">
							<div className="section-title text-left mb--30">
								<h2
									className="title"
									style={{
										borderBottom: "0.7px solid",
										marginBottom: "20px",
									}}
								>
									Have a <span className="theme-color">Question?</span>
								</h2>
								<p>
									Explore the most frequently asked questions from other
									Candidates.
								</p>
							</div>
							<div className="faq-area">
								<Accordion className="accodion-style--1" preExpanded={"0"}>
									{FAQList.map((item, index) => {
										return (
											<AccordionItem key={index}>
												<AccordionItemHeading>
													<AccordionItemButton>
														{item.question}
													</AccordionItemButton>
												</AccordionItemHeading>
												<AccordionItemPanel>
													<p>{item.answer}</p>
												</AccordionItemPanel>
											</AccordionItem>
										);
									})}
								</Accordion>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* End Faq Area */}

			{/* Start Back To Top */}
			{showBackToTop && (
				<div className="backto-top" style={{ display: showBackToTop ? "block" : "none" }}>
					<div>
						<div onClick={scrollToTop} className="backto-top">
							<FiChevronUp />
						</div>
					</div>
				</div>
			)}
			{/* End Back To Top */}

			<Footer />
		</>
	);
}
