import { useState, useEffect } from "react";

export default function PageScrollTop() {
	const [showBackToTop, setShowBackToTop] = useState(false);

	const handleScroll = () => {
		if (window.scrollY > 160) {
			setShowBackToTop(true);
		} else {
			setShowBackToTop(false);
		}
	};

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	const scrollToTop = () => {
		window.scrollTo(0, 0);
	};

	return { showBackToTop, scrollToTop };
}
