import PageHelmet from "../../../components/common/Helmet";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../../../components/header/HeaderThree";
import Footer from "../../../components/footer/Footer";
import discountedExams from "../../../assets/images/events/events_08_07_2024/discounted-exams.jpg";
import PageScrollTop from "../../../components/pageScrollTop/PageScrollTop";

export default function DiscountedExamsEvent() {
	const { showBackToTop, scrollToTop } = PageScrollTop();

	return (
		<>
			{/* Start Pagehelmet  */}
			<PageHelmet pageTitle="10% Discount for all ISTQB exams on 30th of July" />
			{/* End Pagehelmet  */}

			<Header />

			{/* Start Breadcrump Area */}
			<div className="rn-page-title-area pt--20 pb--60" data-black-overlay="7">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="rn-page-title text-center pt--100">
								<h2 className="title theme-gradient">
									10% Discount for all ISTQB exams on 30th of July
								</h2>
								<p>08/07/2024</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* End Breadcrump Area */}

			{/* Start Page Wrapper */}
			<div className="rn-service-details ptb--30 bg_color--1">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="service-details-inner">
								<div className="inner">
									{/* Start Single Area */}
									<div className="row sercice-details-content pb--20 align-items-center">
										<div className="col-lg-6 col-12">
											<div className="details mt_md--30 mt_sm--30">
												<h4 className="title">Overview</h4>
												<p>
													We have a hotly prepared announcement that you've been
													waiting for!
												</p>
												<p>
													Get ready for an exclusive 10% discount on ALL ISTQB
													exams throughout this summer! Please, apply the
													discount upon the payment.
												</p>
												<p>
													For more information, click on{" "}
													<a
														style={{ color: "red" }}
														href="http://bit.ly/3jatMF3"
														target="_blank"
													>
														Register Now{" "}
													</a>
													Button.
												</p>
											</div>
										</div>
										<div className="col-lg-6 col-12">
											<img
												className="w-100 d-lg-block d-md-none mt--30"
												src={discountedExams}
												alt="10% Discount on ISTQB Exams Poster"
											/>
											<div
												className="portfolio-button mt--40"
												style={{
													alignItems: "center",
													display: "flex",
													justifyContent: "center",
												}}
											>
												<a
													className="rn-button-style--2 btn-solid"
													href="http://bit.ly/3jatMF3"
													target="_blank"
												>
													Register Now
												</a>
											</div>
										</div>
									</div>
									{/* End Single Area */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* End Page Wrapper */}

			{/* Start Back To Top */}
			{showBackToTop && (
				<div className="backto-top" style={{ display: showBackToTop ? "block" : "none" }}>
					<div>
						<div onClick={scrollToTop} className="backto-top">
							<FiChevronUp />
						</div>
					</div>
				</div>
			)}
			{/* End Back To Top */}

			<Footer />
		</>
	);
}
