import { useEffect, useState } from "react";
import { FaInstagram, FaFacebookF, FaLinkedinIn, FaTelegram   } from "react-icons/fa";
import { FiX, FiMenu } from "react-icons/fi";
import logo from "../../assets/images/logo/logo-sm.png";

const SocialShare = [
	{ Social: <FaFacebookF />, link: "https://www.facebook.com/armstqb.org" },
	{ Social: <FaLinkedinIn />, link: "https://www.linkedin.com/company/armstqb" },
	{ Social: <FaInstagram />, link: "https://www.instagram.com/armstqb/" },
	{ Social: <FaTelegram   />, link: "https://t.me/armstqb" },

];

export default function HeaderThree({ color = "default-color" }) {
	const [isSticky, setIsSticky] = useState(false);

	useEffect(() => {
		window.addEventListener("scroll", function () {
			var value = window.scrollY;
			if (value > 100) {
				document.querySelector(".header--fixed").classList.add("sticky");
				setIsSticky(true);
			} else {
				document.querySelector(".header--fixed").classList.remove("sticky");
				setIsSticky(false);
			}
		});

		let elements = document.querySelectorAll(".has-dropdown > a");
		for (let i in elements) {
			if (elements.hasOwnProperty(i)) {
				elements[i].onclick = function () {
					this.parentElement.querySelector(".submenu").classList.toggle("active");
					this.classList.toggle("open");
				};
			}
		}
	}, []);

	// const headerRightStyle = {
	// 	backgroundColor: isSticky ? "" : "rgba(246, 246, 246, 0.3)",
	// 	paddingLeft: "10px",
	// };

	const menuTrigger = () => {
		document.querySelector(".header-wrapper").classList.toggle("menu-open");
	};

	const closeMenuTrigger = () => {
		document.querySelector(".header-wrapper").classList.remove("menu-open");
	};

	const closeSidebar = () => {
		document.querySelector(".header-wrapper").classList.remove("menu-open");
	};

	return (
		<header className={`header-area header-style-two header--fixed ${color}`}>
			<div className="header-wrapper">
				<div className="header-left d-flex align-items-center">
					<div className="logo">
						<a href="/">{<img src={logo} alt="Logo" />}</a>
					</div>
					<nav className="mainmenunav d-lg-block ml--30">
						<ul className="mainmenu">
							<li>
								<a onClick={closeSidebar} href="/">
									Home
								</a>
							</li>

							{/* <li className="has-dropdown">
								<Link to="/about-istqb">About ISTQB</Link>
								<ul className="submenu">
									<li>
										<Link to="http://scr.istqb.org/" target="_blank">
											SCR
										</Link>
									</li>
									<li>
										<Link
											to="https://glossary.istqb.org/en_US/search?term=&exact_matches_first=true"
											target="_blank"
										>
											Glossary
										</Link>
									</li>
								</ul>
							</li> */}

							<li className="has-dropdown">
								<a>ISTQB</a>
								<ul className="submenu">
									<li>
										<a href="/about-istqb">About ISTQB</a>
									</li>
									<li>
										<a target="_blank" href="http://scr.istqb.org/">
											SCR
										</a>
									</li>
									<li>
										<a
											target="_blank"
											href="https://glossary.istqb.org/en_US/search?term=&exact_matches_first=true"
										>
											Glossary
										</a>
									</li>
								</ul>
							</li>

							{/* <li>
								<a onClick={closeSidebar} href="/about-istqb">
									About ISTQB
								</a>
							</li> */}
							<li>
								<a onClick={closeSidebar} href="/certifications">
									Certifications
								</a>
							</li>
							<li>
								<a onClick={closeSidebar} href="/partners">
									Partners
								</a>
							</li>
							<li>
								<a onClick={closeSidebar} href="/events">
									Events
								</a>
							</li>
							<li>
								<a onClick={closeSidebar} href="/news">
									News
								</a>
							</li>
							<li>
								<a onClick={closeSidebar} href="/faqs">
									FAQs
								</a>
							</li>
							{/* <li>
								<a
									onClick={closeSidebar}
									target="_blank"
									href="http://scr.istqb.org/"
								>
									SCR
								</a>
							</li>
							<li>
								<a
									onClick={closeSidebar}
									target="_blank"
									href="https://glossary.istqb.org/en_US/search?term=&exact_matches_first=true"
								>
									Glossary
								</a>
							</li> */}
							<li>
								<a onClick={closeSidebar} href="/contact">
									Contact
								</a>
							</li>
						</ul>
					</nav>
				</div>
				<div className="header-right">
					<div className="social-share-inner">
						{isSticky ? (
							<ul className="social-share social-style--2 color-black d-flex justify-content-start liststyle">
								{SocialShare.map((val, i) => (
									<li key={i}>
										<a href={`${val.link}`} target="_blank">
											{val.Social}
										</a>
									</li>
								))}
							</ul>
						) : (
							<ul className="social-share social-style--2 color-white d-flex justify-content-start liststyle">
								{SocialShare.map((val, i) => (
									<li key={i}>
										<a href={`${val.link}`} target="_blank">
											{val.Social}
										</a>
									</li>
								))}
							</ul>
						)}
					</div>
					{/* Start Humberger Menu  */}
					<div className="humberger-menu d-block d-lg-none pl--20">
						<span onClick={menuTrigger} className="menutrigger text-white">
							<FiMenu />
						</span>
					</div>
					{/* End Humberger Menu  */}
					<div className="close-menu d-block d-lg-none">
						<span onClick={closeMenuTrigger} className="closeTrigger">
							<FiX />
						</span>
					</div>
				</div>
			</div>
		</header>
	);
}
