import armsoft from "../../assets/images/logos/armsoft_logo.png";
import testingArt from "../../assets/images/logos/testingart_logo.png";
import testingArtDE from "../../assets/images/logos/testingart_de_logo.png";
import acba from "../../assets/images/logos/acba_logo.png";
import goya from "../../assets/images/logos/goya_logo.png";
import synergy from "../../assets/images/logos/synergy_logo.png";
import volo from "../../assets/images/logos/volo_logo.png";
import zealous from "../../assets/images/logos/zealous_logo.png";
import platinumTier from "../../assets/images/partnerProgramTiers/platinum.png";
import goldTier from "../../assets/images/partnerProgramTiers/gold.png";
import silverTier from "../../assets/images/partnerProgramTiers/silver.png";
import React from "react";

const partnersInfoList = [
	{
		name: "Armenian Software LLC",
		siteURL: "https://www.armsoft.am/?p=home&lang=en",
		logo: armsoft,
		logoAlt: "ArmSoft Logo",
		location: "Yerevan, Armenia",
		partnershipLevelLogo: platinumTier,
		partnershipLevelAlt: "Platinum Tier",
		partnershipURL: "https://partner.istqb.org/istqb-partners/find-a-partner.html#IDP=203",
	},
	{
		name: "TestingArt LLC",
		siteURL: "https://www.testingart.com/",
		logo: testingArt,
		logoAlt: "TestingArt Logo",
		location: "Yerevan, Armenia",
		partnershipLevelLogo: goldTier,
		partnershipLevelAlt: "Gold Tier",
		partnershipURL: "https://partner.istqb.org/istqb-partners/find-a-partner.html#IDP=257",
	},
	{
		name: "TestingArt UG",
		siteURL: "https://testingart.de/",
		logo: testingArtDE,
		logoAlt: "TestingArt-DE Logo",
		location: "Ludwigsburg, Germany",
		partnershipLevelLogo: goldTier,
		partnershipLevelAlt: "Gold Tier",
		partnershipURL: "https://partner.istqb.org/istqb-partners/find-a-partner.html#IDP=513",
	},
	{
		name: "ACBA Bank OJSC",
		siteURL: "https://www.acba.am/en/",
		logo: acba,
		logoAlt: "ACBA Logo",
		location: "Yerevan, Armenia",
		partnershipLevelLogo: silverTier,
		partnershipLevelAlt: "Silver Tier",
		partnershipURL: "https://partner.istqb.org/istqb-partners/find-a-partner.html#IDP=412",
	},
	{
		name: "Goya CJSC",
		siteURL: "https://goya.am/",
		logo: goya,
		logoAlt: "Goya Logo",
		location: "Yerevan, Armenia",
		partnershipLevelLogo: silverTier,
		partnershipLevelAlt: "Silver Tier",
		partnershipURL: "https://partner.istqb.org/istqb-partners/find-a-partner.html#IDP=452",
	},
	{
		name: "Synergy",
		siteURL: "https://www.synisys.com/",
		logo: synergy,
		logoAlt: "Synergy Logo",
		location: "Yerevan, Armenia",
		partnershipLevelLogo: silverTier,
		partnershipLevelAlt: "Silver Tier",
		partnershipURL: "https://partner.istqb.org/istqb-partners/find-a-partner.html#IDP=535",
	},
	{
		name: "Volo LLC",
		siteURL: "https://volo.global/",
		logo: volo,
		logoAlt: "Volo Logo",
		location: "Yerevan, Armenia",
		partnershipLevelLogo: silverTier,
		partnershipLevelAlt: "Silver Tier",
		partnershipURL: "https://partner.istqb.org/istqb-partners/find-a-partner.html#IDP=530",
	},
	{
		name: "Zealous",
		siteURL: "https://zealous.tech/",
		logo: zealous,
		logoAlt: "Zealous Logo",
		location: "Yerevan, Armenia",
		partnershipLevelLogo: silverTier,
		partnershipLevelAlt: "Silver Tier",
		partnershipURL: "https://partner.istqb.org/istqb-partners/find-a-partner.html#IDP=282",
	},
];

function ShowTableBodyRows({
	name,
	siteURL,
	logo,
	logoAlt,
	location,
	partnershipLevelLogo,
	partnershipLevelAlt,
	partnershipURL,
}) {
	return (
		<tr style={{ borderBottom: "1px solid #ecf0f1" }}>
			<td
				style={{
					padding: "10px",
					borderRight: "1px solid #ecf0f1",
					borderLeft: "1px solid #ecf0f1",
					textAlign: "center",
				}}
			>
				{name}
			</td>
			<td
				style={{
					padding: "10px",
					borderRight: "1px solid #ecf0f1",
					borderLeft: "1px solid #ecf0f1",
				}}
			>
				<a href={siteURL} target="_blank">
					<img src={logo} alt={logoAlt} style={{ minWidth: "150px" }} />
				</a>
			</td>
			<td
				style={{
					padding: "10px",
					borderRight: "1px solid #ecf0f1",
					textAlign: "center",
				}}
			>
				{location}
			</td>
			<td
				style={{
					padding: "10px",
					borderRight: "1px solid #ecf0f1",
					justifyContent: "center",
					display: "flex",
				}}
			>
				<img src={partnershipLevelLogo} alt={partnershipLevelAlt} style={{ maxWidth: "300px" }} />
			</td>
			<td
				style={{
					padding: "10px",
					borderRight: "1px solid #ecf0f1",
					textAlign: "center",
				}}
			>
				<a href={partnershipURL} target="_blank" style={{ color: "blue" }}>
					{" "}
					Open Partner details page
				</a>
			</td>
		</tr>
	);
}

export default function PartnersList() {
	return (
		<>
			<table style={{ width: "100%", overflowX: "auto" }}>
				<thead>
					<tr style={{ background: "#3498db", color: "#fff" }}>
						<th
							style={{
								padding: "10px",
								textAlign: "center",
								borderBottom: "2px solid #000000",
								borderRight: "2px solid #ecf0f1",
							}}
						>
							Name
						</th>
						<th
							style={{
								padding: "10px",
								textAlign: "center",
								borderBottom: "2px solid #000000",
								borderRight: "2px solid #ecf0f1",
							}}
						>
							Logo
						</th>
						<th
							style={{
								padding: "10px",
								textAlign: "center",
								borderBottom: "2px solid #000000",
								borderRight: "2px solid #ecf0f1",
							}}
						>
							Location
						</th>
						<th
							style={{
								padding: "10px",
								textAlign: "center",
								borderBottom: "2px solid #000000",
								borderRight: "2px solid #ecf0f1",
							}}
						>
							Partnership Level
						</th>
						<th
							style={{
								padding: "10px",
								textAlign: "center",
								borderBottom: "2px solid #000000",
							}}
						>
							Partnership URL
						</th>
					</tr>
				</thead>
				<tbody>
					{partnersInfoList.map((partner, index) => (
						<ShowTableBodyRows key={index} {...partner} />
					))}
				</tbody>
			</table>
		</>
	);
}
