import PageHelmet from "../components/common/Helmet";
import Breadcrumb from "../components/common/Breadcrumb";
import { FiChevronUp, FiCheck } from "react-icons/fi";
import { FaMobileAlt, FaRegEnvelope } from "react-icons/fa";
import Header from "../components/header/HeaderThree";
import Footer from "../components/footer/Footer";
import PartnersList from "../components/partners/PartnersList";
import globalTier from "../assets/images/partnerProgramTiers/global.png";
import platinumTier from "../assets/images/partnerProgramTiers/platinum.png";
import goldTier from "../assets/images/partnerProgramTiers/gold.png";
import silverTier from "../assets/images/partnerProgramTiers/silver.png";
import PageScrollTop from "../components/pageScrollTop/PageScrollTop";

const partnershipPointsList = [
	"Foundation Level - 1 point",
	"Specialist Certifications (except Security Tester and Test Automation Engineer) - 1 point",
	"Specialist Certifications (Security Tester or Test Automation Engineer) - 3 points",
	"Advanced Level - 3 points per module",
	"Expert Level - 5 points per module",
];

const programBenefitsList = [
	"Permission to use the ISTQB® Partnership Program logo (and other permitted marketing material) on organisation's website.",
	"Public recognition of investments in the certification of tester personnel.",
	"Specific recognition documentation, indicating business unit, validity and level.",
	"Listing of the organization on ISTQB® website.",
	"Listing of the organization on the National ISTQB® Member Board's or Exam Provider's websites.",
	"Special privileges with regard to ISTQB® related events and conferences.",
	"Eligibility to receive the Beta version of new ISTQB® Syllabi with the opportunity to contribute to their review.",
	"Mutual recognition of ISEB and ISTQB®.",
	"Further benefits that National ISTQB® Member Boards and/or Exam Providers may offer on local level.",
];

export default function Partners() {
	const { showBackToTop, scrollToTop } = PageScrollTop();

	const showPartnershipPoints = partnershipPointsList.map((item, index) => {
		return <li key={index}>{item}</li>;
	});

	const showprogramBenefits = programBenefitsList.map((item, index) => {
		return <li key={index}>{item}</li>;
	});

	return (
		<>
			<PageHelmet pageTitle="Partners" />

			{/* Start Header Area  */}
			<Header />
			{/* End Header Area  */}

			{/* Start Breadcrump Area */}
			<Breadcrumb title={"Our Partners"} page={"partners"} />
			{/* End Breadcrump Area */}

			{/* Start Page Wrapper  */}
			<main className="page-wrapper">
				{/* Start Brand Area  */}
				<div className="rn-brand-area ptb--60 bg_color--5">
					<div className="container">
						<div className="row">
							<div className="col-lg-12 mb--40">
								<div className="section-title text-left service-style--3 mb--30 mb_sm--0">
									<h2
										className="title"
										style={{
											borderBottom: "0.7px solid",
											marginBottom: "20px",
										}}
									>
										ISTQB® Partner Program
									</h2>
									<p>
										The ISTQB® Partner Program recognizes organizations with a
										demonstrated commitment to software testing certifications.
									</p>
									<p>
										The Program comprises four levels of partnerships (Silver,
										Gold, Platinum and Global) and the partnership level of an
										organisation is determined through the number of
										certification points it has accumulated. The partnership
										program is annual and needs to be renewed each year.
									</p>
									<p>For more details, please contact ArmSTQB:</p>
									<ul className="list-style--1">
										<li>
											<b>Arshak Balyan</b>
										</li>
										<li>
											<FaMobileAlt />:{" "}
											<a href="tel:+374 94 310033">+374 94 310033</a>
										</li>
										<li>
											<FaRegEnvelope />:{" "}
											<a href="mailto:arshak.balyan@armstqb.org">
												arshak.balyan@armstqb.org
											</a>
										</li>
									</ul>
								</div>
								<div className="col-lg-12 col-md-12 col-sm-12 col-12">
									<h3>Our Partners</h3>
									<div style={{ overflowX: "auto", maxWidth: "100%" }}>
										<PartnersList brandstyle="brandstyle--2" />
									</div>
								</div>
								<div className="row about-us-list mt_sm--30">
									<h3 className="title">Partnership tiers</h3>

									{/* Start PRicing Table Area  */}
									<div className="col-lg-6 col-md-6 col-12 mb--30">
										<div className="rn-pricing">
											<div
												style={{ minHeight: "610px" }}
												className="pricing-table-inner"
											>
												<div className="pricing-header">
													<h4 className="title">Platinum Partner</h4>
													<div className="pricing">
														<span>
															{" "}
															<img
																src={platinumTier}
																alt="Platinum Tier"
																style={{ maxWidth: "200px" }}
															/>
														</span>
													</div>
												</div>
												<div className="pricing-body">
													<ul className="list-style--1">
														<h5>Requirements</h5>
														<ul className="list-style--1 mb--30">
															<li>
																At least 30 certification
																points, earned through
																certificates held by employees
																or external consultants who
																provide services for more than
																70% of their time.{" "}
																<b>
																	At least three Advanced
																	Level Certificates.
																</b>
															</li>
														</ul>
														<h5>The cost</h5>
														<ul className="list-style--1">
															<li>
																The cost of eight Foundation
																Level exams, according to the
																current rates set by ArmSTQB
																plus €100 one time
																registration fee. Discount
																will apply and no registration
																fees on partnership renewal
																process.
															</li>
														</ul>
													</ul>
												</div>
											</div>
										</div>
									</div>
									{/* End PRicing Table Area  */}

									{/* Start PRicing Table Area  */}
									<div className="col-lg-6 col-md-6 col-12 mb--30">
										<div className="rn-pricing">
											<div
												style={{ minHeight: "610px" }}
												className="pricing-table-inner"
											>
												<div className="pricing-header">
													<h4 className="title">Gold Partner</h4>
													<div className="pricing">
														<span>
															{" "}
															<img
																src={goldTier}
																alt="Gold Tier"
																style={{ maxWidth: "200px" }}
															/>
														</span>
													</div>
												</div>
												<div className="pricing-body">
													<ul className="list-style--1">
														<h5>Requirements</h5>
														<ul className="list-style--1 mb--30">
															<li>
																At least 14 certification
																points, earned through
																certificates held by employees
																or external consultants who
																provide services for more than
																70% of their time.{" "}
																<b>
																	At least one Advanced
																	Level Certificate.
																</b>
															</li>
														</ul>
														<h5>The cost</h5>
														<ul className="list-style--1">
															<li>
																The cost of four Foundation
																Level exams, according to the
																current rates set by ArmSTQB
																plus €100 one time
																registration fee. Discount
																will apply and no registration
																fees on partnership renewal
																process.
															</li>
														</ul>
													</ul>
												</div>
											</div>
										</div>
									</div>
									{/* End PRicing Table Area  */}

									{/* Start PRicing Table Area  */}
									<div className="col-lg-6 col-md-6 col-12 mb--30">
										<div className="rn-pricing">
											<div
												style={{ minHeight: "610px" }}
												className="pricing-table-inner"
											>
												<div className="pricing-header">
													<h4 className="title">Silver Partner</h4>
													<div className="pricing">
														<span>
															<img
																src={silverTier}
																alt="Silver Tier"
																style={{ maxWidth: "200px" }}
															/>
														</span>
													</div>
												</div>
												<div className="pricing-body">
													<h5>Requirements</h5>
													<ul className="list-style--1 mb--30">
														<li>
															At least 5 certification points,
															earned through certificates held by
															employees or external consultants
															who provide services for more than
															70% of their time.
														</li>
													</ul>
													<h5>The cost</h5>
													<ul className="list-style--1">
														<li>
															The cost of two Foundation Level
															exams, according to the current
															rates set by ArmSTQB plus €100 one
															time registration fee. Discount
															will apply and no registration fees
															on partnership renewal process.
														</li>
													</ul>
												</div>
											</div>
										</div>
									</div>
									{/* End PRicing Table Area  */}

									{/* Start PRicing Table Area  */}
									<div className="col-lg-6 col-md-6 col-12 mb--30">
										<div style={{ minHeight: "610px" }} className="rn-pricing">
											<div className="pricing-table-inner">
												<div className="pricing-header">
													<h4 className="title">Global Partner</h4>
													<div className="pricing">
														<span>
															{" "}
															<img
																src={globalTier}
																alt="Global Tier"
																style={{ maxWidth: "200px" }}
															/>
														</span>
													</div>
												</div>
												<div className="pricing-body">
													<ul className="list-style--1">
														<h5>Requirements</h5>
														<ul className="list-style--1 mb--30">
															<li>
																A minimum of one Platinum
																Partnership and two Gold
																Partnerships in at least two
																different countries.
															</li>
														</ul>
														<h5>The cost</h5>
														<ul className="list-style--1">
															<li>
																Flat fee of €2,000 per year.
															</li>
														</ul>
													</ul>
												</div>
											</div>
										</div>
									</div>
									{/* End PRicing Table Area  */}
								</div>

								<div className="about-us-list mb--40">
									<h3 className="title">Partnership Points</h3>
									<p>
										Partnership points are accumulated based on the ISTQB®
										certifications that staff hold.
									</p>
									<p>
										The points that are allocated depend on the certification
										level, as follows:
									</p>

									<ul className="list-style">{showPartnershipPoints}</ul>
								</div>

								<div className="about-us-list mb--40">
									<h3 className="title">Program Benefits</h3>
									<p>The major benefits of the ISTQB® Partner Program include:</p>

									<ul className="list-style">{showprogramBenefits}</ul>
								</div>

								<div className="about-us-list">
									<h3 className="title">
										Addition to benefits of the ISTQB®'s Partner Program by
										ArmSTQB
									</h3>
									<p>
										ArmSTQB adds benefits described below to those which ISTQB®
										defined earlier:
									</p>

									<ul className="list-style">
										<li>
											Special discount for all ISTQB exams during the
											partnership (will be applied to the current price):
										</li>
										<ul>
											<li style={{ listStyle: "none" }}>
												Silver partners – 5%
											</li>
											<li style={{ listStyle: "none" }}>
												Gold partners – 10%
											</li>
											<li style={{ listStyle: "none" }}>
												Platinum partners – 15%
											</li>
											<li style={{ listStyle: "none" }}>
												Global partners – 20%
											</li>
										</ul>
										<li>
											Discounts on paid events organized by ArmSTQB (will be
											offered upon the purchase).
										</li>
										<li>
											Special seat reservation on free entrance events
											organized by ArmSTQB:
										</li>
										<ul>
											<li style={{ listStyle: "none" }}>
												Silver partners – up to 2 seats
											</li>
											<li style={{ listStyle: "none" }}>
												Gold partners – up to 4 seats
											</li>
											<li style={{ listStyle: "none" }}>
												Platinum partners – up to 6 seats
											</li>
											<li style={{ listStyle: "none" }}>
												Global partners – up to 8 seats
											</li>
										</ul>
										<li>Newsletter subscription for upcoming events.</li>
										<li>
											Special conditions of an event organized for the
											partner (will be offered upon the organization).
										</li>
										<li>
											Discount on training organized by ArmSTQB (will be
											offered upon the deal).
										</li>
										<li>
											Recognition and promotion of the partnership status
											using all platforms and social media under ArmSTQB
											control.
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* End Brand Area  */}
			</main>
			{/* End Page Wrapper  */}

			{/* Start Back To Top */}
			{showBackToTop && (
				<div className="backto-top" style={{ display: showBackToTop ? "block" : "none" }}>
					<div>
						<div onClick={scrollToTop} className="backto-top">
							<FiChevronUp />
						</div>
					</div>
				</div>
			)}
			{/* End Back To Top */}

			{/* Start Footer Area  */}
			<Footer />
			{/* End Footer Area  */}
		</>
	);
}
