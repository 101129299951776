import ContactForm from "./ContactForm";

export default function ContactTwo() {
	return (
		<div className="contact-form--1">
			<div className="container">
				<div className="row row--35 align-items-start">
					<div className="col-lg-6 order-2 order-lg-1">
						<div className="section-title text-left mb--50">
							<h2 className="title">Contact Us.</h2>
							<p className="description">
								Have any suggestions or questions? Feel free to contact us!
							</p>
						</div>
						<div className="form-wrapper">
							<ContactForm />
						</div>
					</div>
					{/* GOOGLE MAP HERE */}
					<div className="col-lg-6 order-2 order-lg-2 mt--40">
						<div className="thumbnail mb_md--30 mb_sm--30">
							<iframe
								src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3049.3868103374352!2d44.506949576430145!3d40.15594067147!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x406abd000835fc85%3A0x7796df5f56667042!2sArmSTQB!5e0!3m2!1sen!2sam!4v1707923871627!5m2!1sen!2sam"
								style={{
									width: "100%",
									height: "550px",
									border: 0,
									allowfullscreen: "",
									loading: "lazy",
									referrerpolicy: "no-referrer-when-downgrade",
								}}
							></iframe>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
