import PageHelmet from "../../../components/common/Helmet";
import { FiChevronUp } from "react-icons/fi";
import Header from "../../../components/header/HeaderThree";
import Footer from "../../../components/footer/Footer";
import QASummerCampNewsGallery from "./QASummerCampNews_Gallery";
import PageScrollTop from "../../../components/pageScrollTop/PageScrollTop";

export default function QASummerCampNews() {
	const { showBackToTop, scrollToTop } = PageScrollTop();

	return (
		<>
			{/* Start Pagehelmet  */}
			<PageHelmet pageTitle="InnoFest Hackathon" />
			{/* End Pagehelmet  */}

			<Header />

			{/* Start Breadcrump Area */}
			<div className="rn-page-title-area pt--20 pb--60" data-black-overlay="7">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="rn-page-title text-center pt--100">
								<h2 className="title theme-gradient">QA Summer Camp</h2>
								<p>06/08/2024</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* End Breadcrump Area */}

			{/* Start Page Wrapper */}
			<div className="rn-service-details ptb--30 bg_color--1">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="service-details-inner">
								<div className="inner">
									{/* Start Single Area */}
									<div className="row sercice-details-content pb--20 align-items-center">
										<div className="col-lg-12 col-12">
											<div className="details mt_md--30 mt_sm--30">
												<h4 className="title">Overview</h4>
												<p>
													What a fantastic weekend at our QA Summer
													Camp!
												</p>
												<p>
													The first but not the last camp mission is
													now completed.
												</p>
												<p>
													A huge thanks to everyone who participated
													and made this event so friendly and cozy. We
													mastered the hiking trail to Lastiver and
													then enjoyed some well-deserved
													entertainment. Everything went smoothly,
													filled with great communication, fun,
													laughter, and songs.
													<br />
													The next adventure together is coming soon!
												</p>
												<h4 className="title">Gallery</h4>
												<QASummerCampNewsGallery column="col-lg-3 col-md-6 col-sm-6 col-12" />
											</div>
										</div>
									</div>
									{/* End Single Area */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* End Page Wrapper */}

			{/* Start Back To Top */}
			{showBackToTop && (
				<div className="backto-top" style={{ display: showBackToTop ? "block" : "none" }}>
					<div>
						<div onClick={scrollToTop} className="backto-top">
							<FiChevronUp />
						</div>
					</div>
				</div>
			)}
			{/* End Back To Top */}

			<Footer />
		</>
	);
}
