import PageHelmet from "../../components/common/Helmet";
import { FiChevronUp } from "react-icons/fi";
import Header from "../../components/header/HeaderThree";
import Footer from "../../components/footer/Footer";
import cttaeLogo from "../../assets/images/logos/ctal_tae_2_logo.png";
import cttaeTable from "../../assets/images/tables/ctal_tae_2_table.png";
import ExamStructure from "./ExamStructure";
import PageScrollTop from "../../components/pageScrollTop/PageScrollTop";

const businessOutcomesList = [
	"Describe the purpose of test automation.",
	"Understand test automation through the software development lifecycle.",
	"Understand the Configuration of an Infrastructure to Enable Test Automation.",
	"Learn the evaluation process for selecting the right tools and strategies.",
	"Understand design concepts for building modular and scalable test automation solutions.",
	"Select an approach, including a pilot, to plan test automation deployment within the software development lifecycle.",
	"Design and develop (new or modified) test automation solutions that meet technical needs.",
	"Consider scope and approach of test automation and maintenance of testware.",
	"Understand how automated tests integrate within CI/CD pipelines.",
	"Understand how to collect, analyze, and report on test automation data in order to inform stakeholders.",
	"Verify the test automation infrastructure.",
	"Define continuous improvement opportunities for test automation.",
];

export default function TestAutomationEngineer() {
	const { showBackToTop, scrollToTop } = PageScrollTop();

	const showBusinessOutcomes = businessOutcomesList.map((item, index) => {
		return <li key={index}>{item}</li>;
	});

	return (
		<>
			{/* Start Pagehelmet  */}
			<PageHelmet pageTitle="ISTQB Specialist Test Automation Engineer v2.0 [NEW!]" />
			{/* End Pagehelmet  */}

			<Header />

			{/* Start Breadcrump Area */}
			<div
				className="breadcrumb-area rn-bg-color pt--20 pb--60 bg_image bg_image--certifications"
				data-black-overlay="6"
			>
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="rn-page-title text-center pt--100">
								<h2 className="title theme-gradient">
									Advanced Level Test Automation Engineer
								</h2>
								<p>
									Certified Tester Advanced Level Test Automation Engineer (CTAL-TAE) v2.0
									[NEW!]
								</p>
								<div className="portfolio-button">
									<a
										className="rn-button-style--2 btn-solid-register"
										href="http://bit.ly/3jatMF3"
										target="_blank"
									>
										Register Now
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* End Breadcrump Area */}

			{/* Start Page Wrapper */}
			<div className="rn-service-details ptb--30 bg_color--1">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="service-details-inner">
								<div className="inner">
									{/* Start Single Area */}
									<div className="row sercice-details-content pb--20 align-items-center">
										<div className="col-lg-10 col-12">
											<div className="details mt_md--30 mt_sm--30">
												<h4 className="title">Overview</h4>
												<p>
													The Certified Tester Advanced Level Test Automation
													Engineering (CTAL-TAE) qualification is aimed at anyone
													involved in software testing and test automation. This
													includes people in roles such as testers, test
													analysts, test automation engineers, test consultants,
													test architects, test managers, and software
													developers. This qualification is also appropriate for
													anyone who wants a basic understanding of test
													automation, such as project managers, quality managers,
													software development managers, business analysts, IT
													directors and management consultants.
												</p>

												<h4 className="title">Audience</h4>
												<p>
													The Test Automation Engineering syllabus is targeted to
													the test engineer looking to implement or improve on
													test automation. It defines methods and practices that
													can support a sustainable solution.
													<br />
													Other guidelines and reference models relating to test
													automation solutions are software engineering standards
													for the selected software development lifecycles,
													programming technologies, and formatting standards.
													This syllabus does not teach software engineering.
													However, a test automation engineer is expected to have
													skills, experience, and expertise in software
													engineering.
													<br />
													Furthermore, a test automation engineer needs to be
													aware of industry programming and documentation
													standards and best practices to make use of them while
													developing a test automation solution. These practices
													can increase maintainability, reliability, and security
													of the test automation solution. Such standards are
													typically based on quality characteristics.
													<br />
													To gain this certification, candidates must hold the{" "}
													<a href="/certifications/certified-tester-foundation-level">
														<b>Certified Tester Foundation Level v4.0</b>
													</a>
													certificate or previous versions of the Foundation
													Level certificate and have sufficient practical
													experience. Please contact an ISTQB® Member Board or
													Exam Provider to determine the specific practical
													experience criteria.
												</p>
											</div>
										</div>
										<div className="col-lg-2 col-4">
											<div className="thumb">
												<img
													className="w-100 d-lg-block d-md-none"
													src={cttaeLogo}
													alt="CTTAE Logo"
													data-bs-toggle="modal"
													data-bs-target="#cttaeLogoModal"
												/>
											</div>
										</div>

										{/* MODAL START*/}
										<div
											className="modal fade"
											id="cttaeLogoModal"
											tabIndex="-1"
											aria-hidden="true"
										>
											<div className="modal-dialog">
												<div className="modal-content">
													<div className="modal-body">
														<button
															type="button"
															className="btn-close"
															data-bs-dismiss="modal"
															aria-label="Close"
														></button>
														<img
															className="w-100 d-lg-block d-md-none"
															src={cttaeLogo}
														/>
													</div>
												</div>
											</div>
										</div>
										{/* MODAL END*/}
									</div>
									{/* End Single Area */}

									{/* Start Single Area */}
									<div className="row sercice-details-content pb--20 align-items-center">
										<div className="col-lg- col-12 order-2 order-lg-1">
											<div className="details mt_md--30 mt_sm--30">
												<h4 className="title">Contents</h4>
												<div className="thumb" style={{ paddingTop: "13px" }}>
													<img
														className="w-100"
														src={cttaeTable}
														alt="CTTAE Content Table"
													/>
												</div>
											</div>
										</div>
									</div>
									{/* End Single Area */}

									{/* Start Single Area */}
									<div className="row sercice-details-content pb--20 align-items-center">
										<div className="col-lg-12 col-10 order-2 order-lg-4">
											<div className="details mt_md--30 mt_sm--30">
												<h4 className="title">Exam Structure</h4>
												<ExamStructure exam="ctal-tae2" />
											</div>
										</div>
									</div>
									{/* End Single Area */}

									{/* Start Single Area */}
									<div className="row sercice-details-content pb--20 align-items-center">
										<div className="col-lg- col-12 order-2 order-lg-1">
											<div className="details mt_md--30 mt_sm--30">
												<h4 className="title">Business Outcomes</h4>
												<p>
													People who have passed the exam should be able to
													accomplish the following Business Objectives:
												</p>
												<ul className="liststyle">{showBusinessOutcomes}</ul>
											</div>
										</div>
									</div>
									{/* End Single Area */}

									{/* Start Single Area */}
									<div className="row sercice-details-content pb--20 align-items-center">
										<div className="col-lg- col-12 order-2 order-lg-1">
											<div className="details mt_md--30 mt_sm--30">
												<h4 className="title">More Information</h4>
												<p>
													Training is available from Accredited Training
													Providers (classroom, virtual, and e-learning). We
													highly recommend attending accredited training as it
													ensures that an ISTQB® Member Board has assessed the
													materials for relevance and consistency against the
													syllabus.
													<br />
													Self-study, using the syllabus and recommended reading
													material, is also an option when preparing for the
													exam.
													<br />
													<b>
														Holders of this certification may choose to
														proceed to other Core, Agile, or Specialist stream
														certifications.
													</b>
												</p>
											</div>
										</div>
									</div>
									{/* End Single Area */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* End Page Wrapper */}

			{/* Start Back To Top */}
			{showBackToTop && (
				<div className="backto-top" style={{ display: showBackToTop ? "block" : "none" }}>
					<div>
						<div onClick={scrollToTop} className="backto-top">
							<FiChevronUp />
						</div>
					</div>
				</div>
			)}
			{/* End Back To Top */}

			<Footer />
		</>
	);
}
