import PageHelmet from "../../components/common/Helmet";
import { FiChevronUp } from "react-icons/fi";
import Header from "../../components/header/HeaderThree";
import Footer from "../../components/footer/Footer";
import cttaeLogo from "../../assets/images/logos/ct_tae_logo.png";
import cttaeTable from "../../assets/images/tables/ct_tae_table.png";
import ExamStructure from "./ExamStructure";
import PageScrollTop from "../../components/pageScrollTop/PageScrollTop";

const businessOutcomesList = [
	"Contribute to the development of a plan to integrate automated testing within the testing process.",
	"Evaluate tools and technology for automation best fit to each project and organization.",
	"Create an approach and methodology for building a test automation architecture (TAA).",
	"Design and develop (new or modified) test automation solutions that meet the business needs.",
	"Enable the transition of testing from a manual to an automated approach.",
	"Create automated test reporting and metrics collection.",
	"Manage and optimize testing assets to facilitate maintainability and address evolving (test) systems.",
];

export default function TestAutomationEngineer() {
	const { showBackToTop, scrollToTop } = PageScrollTop();

	const showBusinessOutcomes = businessOutcomesList.map((item, index) => {
		return <li key={index}>{item}</li>;
	});

	return (
		<>
			{/* Start Pagehelmet  */}
			<PageHelmet pageTitle="ISTQB Specialist Test Automation Engineer 2016 [Retiring]" />
			{/* End Pagehelmet  */}

			<Header />

			{/* Start Breadcrump Area */}
			<div
				className="breadcrumb-area rn-bg-color pt--20 pb--60 bg_image bg_image--certifications"
				data-black-overlay="6"
			>
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="rn-page-title text-center pt--100">
								<h2 className="title theme-gradient">Specialist Test Automation Engineer</h2>
								<p>Certified Tester Test Automation Engineer (CT-TAE) 2016 [Retiring]</p>
								<div className="portfolio-button">
									<a
										className="rn-button-style--2 btn-solid-register"
										href="http://bit.ly/3jatMF3"
										target="_blank"
									>
										Register Now
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* End Breadcrump Area */}

			{/* Start Page Wrapper */}
			<div className="rn-service-details ptb--30 bg_color--1">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="service-details-inner">
								<div className="inner">
									{/* Start Single Area */}
									<div className="row sercice-details-content pb--20 align-items-center">
										<div className="col-lg-10 col-12">
											<div className="details mt_md--30 mt_sm--30">
												<h4 className="title">Overview</h4>
												<p>
													The ISTQB® Test Automation Engineer (CT-TAE)
													certification focuses on the design, development, and
													maintenance of test automation solutions. It also
													covers the concepts, methods, tools, and processes for
													automating dynamic functional tests, and the
													relationship of those tests to test management,
													configuration management, defect management, software
													development processes, and quality assurance. Methods
													described are generally applicable across a variety of
													software life cycle approaches, types of software
													systems, and test types.
												</p>

												<h4 className="title">Audience</h4>
												<p>
													The Test Automation Engineer certification is aimed at
													people who already have experience in software testing
													and who wish to further develop their expertise in
													automation testing.
													<br />
													To gain this certification, candidates must hold the{" "}
													<a href="/certifications/certified-tester-foundation-level">
														<b>Certified Tester Foundation Level </b>
													</a>
													certificate.
												</p>
											</div>
										</div>
										<div className="col-lg-2 col-4">
											<div className="thumb">
												<img
													className="w-100 d-lg-block d-md-none"
													src={cttaeLogo}
													alt="CTTAE Logo"
													data-bs-toggle="modal"
													data-bs-target="#cttaeLogoModal"
												/>
											</div>
										</div>

										{/* MODAL START*/}
										<div
											className="modal fade"
											id="cttaeLogoModal"
											tabIndex="-1"
											aria-hidden="true"
										>
											<div className="modal-dialog">
												<div className="modal-content">
													<div className="modal-body">
														<button
															type="button"
															className="btn-close"
															data-bs-dismiss="modal"
															aria-label="Close"
														></button>
														<img
															className="w-100 d-lg-block d-md-none"
															src={cttaeLogo}
														/>
													</div>
												</div>
											</div>
										</div>
										{/* MODAL END*/}
									</div>
									{/* End Single Area */}

									{/* Start Single Area */}
									<div className="row sercice-details-content pb--20 align-items-center">
										<div className="col-lg- col-12 order-2 order-lg-1">
											<div className="details mt_md--30 mt_sm--30">
												<h4 className="title">Contents</h4>
												<div className="thumb" style={{ paddingTop: "13px" }}>
													<img
														className="w-100"
														src={cttaeTable}
														alt="CTTAE Content Table"
													/>
												</div>
											</div>
										</div>
									</div>
									{/* End Single Area */}

									{/* Start Single Area */}
									<div className="row sercice-details-content pb--20 align-items-center">
										<div className="col-lg-12 col-10 order-2 order-lg-4">
											<div className="details mt_md--30 mt_sm--30">
												<h4 className="title">Exam Structure</h4>
												<ExamStructure exam="ctal-tae" />
											</div>
										</div>
									</div>
									{/* End Single Area */}

									{/* Start Single Area */}
									<div className="row sercice-details-content pb--20 align-items-center">
										<div className="col-lg- col-12 order-2 order-lg-1">
											<div className="details mt_md--30 mt_sm--30">
												<h4 className="title">Business Outcomes</h4>
												<p>
													Advanced Level testers who have passed the “Advanced
													Test Automation Engineer” module exam should be able to
													accomplish the following Business Objectives:
												</p>
												<ul className="liststyle">{showBusinessOutcomes}</ul>
											</div>
										</div>
									</div>
									{/* End Single Area */}

									{/* Start Single Area */}
									<div className="row sercice-details-content pb--20 align-items-center">
										<div className="col-lg- col-12 order-2 order-lg-1">
											<div className="details mt_md--30 mt_sm--30">
												<h4 className="title">More Information</h4>
												<p>
													Training is available from Accredited Training
													Providers (classroom, virtual, and e-learning). We
													highly recommend attending accredited training as it
													ensures that an ISTQB® Member Board has assessed the
													materials for relevance and consistency against the
													syllabus.
													<br />
													Self-study, using the syllabus and recommended reading
													material, is also an option when preparing for the
													exam.
													<br />
													<b>
														Holders of this certification may choose to
														proceed to other Core, Agile, or Specialist stream
														certifications.
													</b>
												</p>
											</div>
										</div>
									</div>
									{/* End Single Area */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* End Page Wrapper */}

			{/* Start Back To Top */}
			{showBackToTop && (
				<div className="backto-top" style={{ display: showBackToTop ? "block" : "none" }}>
					<div>
						<div onClick={scrollToTop} className="backto-top">
							<FiChevronUp />
						</div>
					</div>
				</div>
			)}
			{/* End Back To Top */}

			<Footer />
		</>
	);
}
