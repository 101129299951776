import FoundationLevel4 from "./FoundationLevel4";
import AgileTester from "./AgileTester";
import TestManager from "./TestManager";
import TestManager3 from "./TestManager3";
import TestAnalyst from "./TestAnalyst";
import TechnicalTestAnalyst from "./TechnicalTestAnalyst";
import GamblingTester from "./GamblingTester";
import MobileTester from "./MobileTester";
import SecurityTester from "./SecurityTester";
import TestAutomationEngineer from "./TestAutomationEngineer";
import TestAutomationEngineer2 from "./TestAutomationEngineer2";
import FoundationLevel3_1 from "./FoundationLevel3_1";
import ArtificialInteligenceTester from "./ArtificialInteligenceTester";

export default function CertificateDetails({ exam }) {
	if (exam === "ctfl3.1") {
		return <FoundationLevel3_1 />;
	} else if (exam === "ctfl4") {
		return <FoundationLevel4 />;
	} else if (exam === "ctat") {
		return <AgileTester />;
	} else if (exam === "ctal-tm") {
		return <TestManager />;
	} else if (exam === "ctal-tm3") {
		return <TestManager3 />;
	} else if (exam === "ctal-ta") {
		return <TestAnalyst />;
	} else if (exam === "ctal-tta") {
		return <TechnicalTestAnalyst />;
	} else if (exam === "ct-ai") {
		return <ArtificialInteligenceTester />;
	} else if (exam === "ct-gt") {
		return <GamblingTester />;
	} else if (exam === "ct-mat") {
		return <MobileTester />;
	} else if (exam === "ctal-sec") {
		return <SecurityTester />;
	} else if (exam === "ctal-tae") {
		return <TestAutomationEngineer />;
	}
	else if (exam === "ctal-tae2") {
		return <TestAutomationEngineer2 />;
	}
}
