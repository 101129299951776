import Slider from "react-slick";
import { newsSlick } from "../../page-demo/script";

const NewsListContent = [
	{
		image: "voloNews",
		path: "/news/silver-partnership-with-volo",
		title: "ISTQB Silver Partnership with Volo",
		description:
			"It was a great pleasure to be hosted by VOLO Company as they achieved ISTQB Silver Partnership status! The certificate presentation was a delightful event, filled with insightful discussions and shared passion for advancing software testing.",
		date: "04/12/2023",
	},
	{
		image: "QASummerCampNews",
		path: "/news/qa-summer-camp",
		title: "QA Summer Camp",
		description: "What a fantastic weekend at our QA Summer Camp!",
		date: "06/08/2024",
	},
	{
		image: "InnoFestHackathonNews",
		path: "/news/innofest-hackathon",
		title: "InnoFest Hackathon",
		description:
			"We are pleased to announce that at the invitation of Arshak Balyan ArmSTQB co-founder ԻննոԻջեւան / InnoIjevan and TestingArt co-founder have participated in the 'Innofest' hackathon organized by Union of Advanced Technology Enterprises-UATE as jury members.",
		date: "28/06/2024",
	},
	{
		image: "ZealousNews",
		path: "/news/extended-partnership-with-zealous",
		title: "ISTQB® Silver Partnership with Zealous extended for another year.",
		description:
			"We are delighted to announce that our ISTQB® Partner program with Zealous Tech, our esteemed 'Silver Partner,' has been extended for another year! This renewal marks a significant milestone in our journey of success and growth.",
		date: "27/06/2024",
	},
	{
		image: "ArmsoftNews",
		path: "/news/extended-partnership-with-armsoft",
		title: "ISTQB® Platinum Partnership with Armsoft extended for another year.",
		description:
			"This partnership milestone in Armenia continues to be a significant achievement, and we are excited to further our journey together. With ArmSoft by our side, we are poised to reach new heights of success and deliver exceptional value.",
		date: "21/06/2024",
	},
	{
		image: "SummerMeetUpNews",
		path: "/news/summer-meetup-1",
		title: "Summer Meetup #1: ISTQB Foundation level 4.0",
		description:
			"We had an incredible time at our event on June 5th, and we want to extend our heartfelt thanks to everyone who joined us. It was a fantastic evening filled with insightful discussions, valuable updates, and great networking opportunities.",
		date: "07/06/2024",
	},
	{
		image: "AIExamNews",
		path: "/news/ai-testing-specialist-exam-available-in-armstqb",
		title: "ISTQB® AI Testing Specialist exam is now available in ArmSTQB",
		description:
			"The ISTQB® AI Testing (CT-AI) certification extends understanding of artificial intelligence and machine learning, most specifically testing AI-based systems and using AI in testing.",
		date: "23/01/2024",
	},
	{
		image: "studyGuideNews",
		path: "/news/ctfl-self-study-guide-release",
		title: "ISTQB® CTFL A Self-Study Guide release",
		description:
			" The 'ISTQB® Certified Tester Foundation Level A Self-Study Guide' book based on ISTQB CTFL Syllabus v4.0 is now out.",
		date: "19/01/2024",
	},
	{
		image: "synergyNews",
		path: "/news/silver-partnership-with-synergy",
		title: "ISTQB Silver Partnership with Synergy",
		description:
			"We are announcing a new chapter in collaboration as Synergy Armenia achieves the distinguished status of ISTQB Silver Partner.",
		date: "07/12/2023",
	},
];

export const NewsSlider = () => {
	return (
		<div className="wrapper portfolio-sacousel-inner mb--55">
			<div className="portfolio-slick-activation mt--30 mt_sm--30">
				<Slider {...newsSlick}>
					{NewsListContent.map((value, index) => (
						<div className="portfolio portfolio-interior-design" key={index}>
							<div className="thumbnail-inner">
								<div className={`thumbnail ${value.image}`}></div>
								<div className={`bg-blr-image ${value.image}`}></div>
							</div>
							<div className="content">
								<div className="inner">
									<h4>
										<a href={value.path}>{value.title}</a>
									</h4>
									<p>{value.date}</p>
									<p
										style={{
											textOverflow: "ellipsis",
											overflow: "hidden",
											display: "-webkit-box",
											WebkitLineClamp: 3,
											WebkitBoxOrient: "vertical",
										}}
									>
										{value.description}
									</p>
									<div className="portfolio-button">
										<a className="rn-btn" href={value.path}>
											View Details
										</a>
									</div>
								</div>
							</div>
						</div>
					))}
				</Slider>
			</div>
		</div>
	);
};
