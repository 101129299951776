import { FaInstagram, FaFacebookF, FaLinkedinIn } from "react-icons/fa";

const SocialShare = [
	{ Social: <FaFacebookF />, link: "https://www.facebook.com/armstqb.org" },
	{ Social: <FaLinkedinIn />, link: "https://www.linkedin.com/company/armstqb" },
	{ Social: <FaInstagram />, link: "https://www.instagram.com/armstqb/" },
];

export default function Footer() {
	return (
		<>
			<footer className="footer-area">
				<div className="footer-wrapper">
					<div className="row align-items-end row--0">
						<div className="col-lg-12">
							<div className="blank-bg-right">
								<div className="footer-right" data-black-overlay="2">
									<div className="row">
										{/* Start Single Widget  */}
										<div className="col-lg-4 col-sm-6 col-12 mt_mobile--30">
											<div className="social-share-inner">
												<ul className="social-share social-style--2 d-flex justify-content-start liststyle">
													{SocialShare.map((val, i) => (
														<li key={i}>
															<a
																href={`${val.link}`}
																target="_blank"
															>
																{val.Social}
															</a>
														</li>
													))}
												</ul>
											</div>
										</div>
										{/* End Single Widget  */}

										<div className="col-lg-8 d-flex align-items-center">
											<div className="copyright-text">
												<p>
													Copyright © 2024 ArmSTQB®. All Rights
													Reserved.
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</footer>
		</>
	);
}
