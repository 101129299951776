import PageHelmet from "../../components/common/Helmet";
import { FiChevronUp } from "react-icons/fi";
import Header from "../../components/header/HeaderThree";
import Footer from "../../components/footer/Footer";
import ctflLogo from "../../assets/images/logos/ctfl_3.1_logo.png";
import ctflTable from "../../assets/images/tables/ctfl_3.1_table.png";
import ExamStructure from "./ExamStructure";
import PageScrollTop from "../../components/pageScrollTop/PageScrollTop";

const businessOutcomesList = [
	"Understand what testing is and why it is beneficial.",
	"Understand fundamental concepts of software testing.",
	"Identify the test approach and activities to be implemented depending on the context of testing.",
	"Assess and improve the quality of documentation.",
	"Increase the effectiveness and efficiency of testing.",
	"Align the test process with the software development lifecycle.",
	"Understand test management principles.",
	"Write and communicate clear and understandable defect reports.",
	"Understand the factors that influence the priorities and efforts related to testing.",
	"Work as part of a cross-functional team.",
	"Know risks and benefits related to test automation.",
	"Identify essential skills required for testing.",
	"Understand the impact of risk on testing.",
	"Effectively report on test progress and quality.",
];

export default function FoundationLevel3_1() {
	const { showBackToTop, scrollToTop } = PageScrollTop();

	const showBusinessOutcomes = businessOutcomesList.map((item, index) => {
		return <li key={index}>{item}</li>;
	});

	return (
		<>
			{/* Start Pagehelmet  */}
			<PageHelmet pageTitle="ISTQB Foundation Level Certified Tester v3.1 [Retired]" />
			{/* End Pagehelmet  */}

			<Header />

			{/* Start Breadcrump Area */}
			<div
				className="breadcrumb-area rn-bg-color pt--20 pb--60 bg_image bg_image--certifications"
				data-black-overlay="6"
			>
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="rn-page-title text-center pt--100">
								<h2 className="title theme-gradient">
									Foundation Level Certified Tester
								</h2>
								<p>Certified Tester Foundation Level (CTFL) v3.1 [Retired]</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* End Breadcrump Area */}

			{/* Start Page Wrapper */}
			<div className="rn-service-details ptb--30 bg_color--1">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="service-details-inner">
								<div className="inner">
									{/* Start Single Area */}
									<div className="row sercice-details-content pb--20 align-items-center">
										<div className="col-lg-10 col-12">
											<div className="details mt_md--30 mt_sm--30">
												<h4 className="title">Overview</h4>
												<p>
													The ISTQB® Certified Tester Foundation Level
													(CTFL) certification provides essential
													testing knowledge that can be put to
													practical use and, very importantly, explains
													the terminology and concepts that are used
													worldwide in the testing domain. CTFL is
													relevant across software delivery approaches
													and practices including Waterfall, Agile,
													DevOps, and Continuous Delivery. CTFL
													certification is recognized as a prerequisite
													to all other ISTQB® certifications where
													Foundation Level is required.
												</p>

												<h4 className="title">Audience</h4>
												<p>
													The Foundation Level certification is
													suitable for anyone who needs to demonstrate
													practical knowledge of the fundamental
													concepts of software testing including people
													in roles such as testers, test analysts, test
													engineers, test consultants, test managers,
													user acceptance testers, and software
													developers. It is also appropriate for
													individuals who need a basic understanding of
													software testing including project managers,
													quality managers, software development
													managers, business analysts, IT directors,
													and management consultants.
												</p>
											</div>
										</div>
										<div className="col-lg-2 col-4">
											<div className="thumb">
												<img
													className="w-100 d-lg-block d-md-none"
													src={ctflLogo}
													alt="CTFL Logo"
													data-bs-toggle="modal"
													data-bs-target="#ctflLogoModal"
												/>
											</div>
										</div>

										{/* MODAL START*/}
										<div
											className="modal fade"
											id="ctflLogoModal"
											tabIndex="-1"
											aria-hidden="true"
										>
											<div className="modal-dialog">
												<div className="modal-content">
													<div className="modal-body">
														<button
															type="button"
															className="btn-close"
															data-bs-dismiss="modal"
															aria-label="Close"
														></button>
														<img
															className="w-100 d-lg-block d-md-none"
															src={ctflLogo}
														/>
													</div>
												</div>
											</div>
										</div>
										{/* MODAL END*/}
									</div>
									{/* End Single Area */}

									{/* Start Single Area */}
									<div className="row sercice-details-content pb--20 align-items-center">
										<div className="col-lg- col-12 order-2 order-lg-1">
											<div className="details mt_md--30 mt_sm--30">
												<h4 className="title">Contents</h4>
												<div
													className="thumb"
													style={{ paddingTop: "13px" }}
												>
													<img
														className="w-100"
														src={ctflTable}
														alt="CTFL Content Table"
													/>
												</div>
											</div>
										</div>
									</div>
									{/* End Single Area */}

									{/* Start Single Area */}
									<div className="row sercice-details-content pb--20 align-items-center">
										<div className="col-lg-12 col-10 order-2 order-lg-4">
											<div className="details mt_md--30 mt_sm--30">
												<h4 className="title">Exam Structure</h4>
												<ExamStructure exam="ctfl3.1" />
											</div>
										</div>
									</div>
									{/* End Single Area */}

									{/* Start Single Area */}
									<div className="row sercice-details-content pb--20 align-items-center">
										<div className="col-lg- col-12 order-2 order-lg-1">
											<div className="details mt_md--30 mt_sm--30">
												<h4 className="title">Business Outcomes</h4>
												<p>
													The Business Outcomes expected of a candidate
													who has achieved the new Foundation Level
													certification are as follows:
												</p>
												<ul className="liststyle">
													{showBusinessOutcomes}
												</ul>
											</div>
										</div>
									</div>
									{/* End Single Area */}

									{/* Start Single Area */}
									<div className="row sercice-details-content pb--20 align-items-center">
										<div className="col-lg- col-12 order-2 order-lg-1">
											<div className="details mt_md--30 mt_sm--30">
												<h4 className="title">More Information</h4>
												<p>
													Training for the Certified Tester Foundation
													Level is available from Accredited Training
													Providers (classroom, virtual, and
													e-learning). We highly recommend attending
													accredited training as it ensures that an
													ISTQB® Member Board has assessed the
													materials for relevance and consistency
													against the syllabus. Self-study, using the
													syllabus and recommended reading material, is
													also an option when preparing for the
													Foundation Level exam. <br />
													<b>
														Holders of this certification will be
														eligible to proceed to the next stage of
														the Core stream and take Test Analyst,
														Technical Test Analyst, or Test
														Management Advanced Level
														certifications. They may also choose to
														follow the Agile or Specialist streams
														to develop specific skills.
													</b>
												</p>
											</div>
										</div>
									</div>
									{/* End Single Area */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* End Page Wrapper */}

			{/* Start Back To Top */}
			{showBackToTop && (
				<div className="backto-top" style={{ display: showBackToTop ? "block" : "none" }}>
					<div>
						<div onClick={scrollToTop} className="backto-top">
							<FiChevronUp />
						</div>
					</div>
				</div>
			)}
			{/* End Back To Top */}

			<Footer />
		</>
	);
}
