import { FaLinkedinIn } from "react-icons/fa";
import arthur from "../../assets/images/team/arthur.png";
import arshak from "../../assets/images/team/arshak.png";
import mane from "../../assets/images/team/mane.png";
import arman from "../../assets/images/team/arman.png";
import albert from "../../assets/images/team/albert.jpg";
import sevak from "../../assets/images/team/sevak.png";
import gurgen from "../../assets/images/team/gurgen.png";
import zvart from "../../assets/images/team/zvart.png";
import anna from "../../assets/images/team/anna.png";
import nune from "../../assets/images/team/nune.png";
import asatur from "../../assets/images/team/asatur.png";
import azat from "../../assets/images/team/azat.png";
import yana from "../../assets/images/team/yana.png";

let data = [
	{
		images: arshak,
		title: "Arshak Balyan",
		designation: "Co-Founder of ArmSTQB",
		socialNetwork: [
			{
				icon: <FaLinkedinIn />,
				url: "https://www.linkedin.com/in/arshak-balyan",
			},
		],
	},
	{
		images: arthur,
		title: "Arthur Mkrtchyan",
		designation: "Co-Founder of ArmSTQB",
		socialNetwork: [
			{
				icon: <FaLinkedinIn />,
				url: "https://www.linkedin.com/in/arthur-mkrtchyan",
			},
		],
	},
	{
		images: mane,
		title: "Mane Chobanyan",
		designation: "Coordinator",
		socialNetwork: [
			{
				icon: <FaLinkedinIn />,
				url: "https://www.linkedin.com/in/mane-chobanyan-512b97224",
			},
		],
	},
	{
		images: arman,
		title: "Arman Alaverdyan",
		designation: "Board Member",
		socialNetwork: [
			{
				icon: <FaLinkedinIn />,
				url: "https://www.linkedin.com/in/arman-alaverdyan",
			},
		],
	},
	{
		images: albert,
		title: "Albert Galstyan",
		designation: "Board Member",
		socialNetwork: [
			{
				icon: <FaLinkedinIn />,
				url: "https://www.linkedin.com/in/albert-galstyan01",
			},
		],
	},
	{
		images: sevak,
		title: "Sevak Mkrtchyan",
		designation: "Board Member",
		socialNetwork: [
			{
				icon: <FaLinkedinIn />,
				url: "https://www.linkedin.com/in/sevak-mkrtchyan-0021",
			},
		],
	},
	{
		images: gurgen,
		title: "Gurgen Hakobyan",
		designation: "Board Member",
		socialNetwork: [
			{
				icon: <FaLinkedinIn />,
				url: "https://www.linkedin.com/in/gurgen-hakobyan-62865951/",
			},
		],
	},
	{
		images: zvart,
		title: "Zvart Baghdasaryan",
		designation: "Board Member",
		socialNetwork: [
			{
				icon: <FaLinkedinIn />,
				url: "https://www.linkedin.com/in/zvart-baghdasaryan-261a67156",
			},
		],
	},
	{
		images: anna,
		title: "Anna Petrosyan",
		designation: "Board Member",
		socialNetwork: [
			{
				icon: <FaLinkedinIn />,
				url: "https://www.linkedin.com/in/anpetrosyan",
			},
		],
	},
	{
		images: nune,
		title: "Nune Chaloyan",
		designation: "Board Member",
		socialNetwork: [
			{
				icon: <FaLinkedinIn />,
				url: "https://www.linkedin.com/in/nune-chaloyan",
			},
		],
	},
	{
		images: azat,
		title: "Azat Ghazaryan",
		designation: "Board Member",
		socialNetwork: [
			{
				icon: <FaLinkedinIn />,
				url: "https://www.linkedin.com/in/azat-ghazaryan/",
			},
		],
	},
	{
		images: yana,
		title: "Yana Gabrielyan",
		designation: "Board Member",
		socialNetwork: [
			{
				icon: <FaLinkedinIn />,
				url: "https://www.linkedin.com/in/yana-gabrielyan-98474b17b/",
			},
		],
	},

	{
		images: asatur,
		title: "Asatur Balyan",
		designation: "Board Member",
		socialNetwork: [
			{
				icon: <FaLinkedinIn />,
				url: "https://www.linkedin.com/in/asatur-balyan-244879302/",
			},
		],
	},
];

export default function Team(props) {
	const itemSlice = data.slice(0, props.item);

	return (
		<div className="row">
			{itemSlice.map((value, i) => (
				<div className={`${props.column}`} key={i}>
					<div className="team">
						<div className="thumbnail">
							<img src={value.images} alt="Member Profile" />
						</div>
						<div className="inner">
							<div className="content">
								<h4 className="title">{value.title}</h4>
								<p className="designation">{value.designation}</p>
								<ul className="social-icon">
									{value.socialNetwork.map((social, index) => (
										<li key={index}>
											<a href={`${social.url}`} target="_blank">
												{social.icon}
											</a>
										</li>
									))}
								</ul>
							</div>
						</div>
					</div>
				</div>
			))}
		</div>
	);
}
