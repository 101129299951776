import { Helmet } from "react-helmet";

export default function PageHelmet({ pageTitle }) {
	return (
		<>
			<Helmet>
				<title>{pageTitle}</title>
				<meta
					name="description"
					content="ArmSTQB - Armenian Software Testing Qualification Board website"
				/>
			</Helmet>
		</>
	);
}
