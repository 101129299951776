import { useState } from "react";
import { Tabs, TabPanel } from "react-tabs";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import synergy1 from "../../../assets/images/news/news_07_12_2023/synergy1.jpg";
import synergy2 from "../../../assets/images/news/news_07_12_2023/synergy2.jpg";
import synergy3 from "../../../assets/images/news/news_07_12_2023/synergy3.jpg";
import synergy4 from "../../../assets/images/news/news_07_12_2023/synergy4.jpg";

const TabOne = [
	{
		image: synergy1,
		bigImage: synergy1,
	},
	{
		image: synergy2,
		bigImage: synergy2,
	},
	{
		image: synergy3,
		bigImage: synergy3,
	},
	{
		image: synergy4,
		bigImage: synergy4,
	},
];

export default function SynergyNewsGallery({ column }) {
	const [tab1, setTab] = useState(0);
	const [isOpen, setIsOpen] = useState(false);

	return (
		<div>
			<Tabs>
				<TabPanel className="row row--10" style={{ marginTop: "-50px" }}>
					{TabOne.map((value, index) => (
						<div className={`${column}`} key={index}>
							{isOpen && (
								<Lightbox
									mainSrc={TabOne[tab1].bigImage}
									nextSrc={TabOne[(tab1 + 1) % TabOne.length]}
									prevSrc={TabOne[(tab1 + TabOne.length - 1) % TabOne.length]}
									onCloseRequest={() => setIsOpen(false)}
									onMovePrevRequest={() =>
										setTab(tab1 + TabOne.length - 1) % TabOne.length()
									}
									onMoveNextRequest={() => setTab((tab1 + 1) % TabOne.length)}
									imageLoadErrorMessage="Image Loading ..."
									enableZoom={true}
									clickOutsideToClose={true}
									imagePadding={120}
								/>
							)}
							<div className="item-portfolio-static">
								<div
									onClick={() => {
										setIsOpen(true);
										setTab(index);
									}}
								>
									<div className="portfolio-static">
										<div className="thumbnail-inner">
											<div className="thumbnail">
												<a href="#gallery">
													<img
														src={value.image}
														alt="Gallery Images"
													/>
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					))}
				</TabPanel>
			</Tabs>
		</div>
	);
}
