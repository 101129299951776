import PageHelmet from "../../components/common/Helmet";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../../components/header/HeaderThree";
import Footer from "../../components/footer/Footer";
import cttmLogo from "../../assets/images/logos/ctal_tm_logo.png";
import cttmTable from "../../assets/images/tables/ctal_tm_v3.0_table.png";
import ExamStructure from "./ExamStructure";
import PageScrollTop from "../../components/pageScrollTop/PageScrollTop";

const businessOutcomesList = [
	"Manage testing in various software development projects by applying test management processes established for the project team or test organization.",
	"Identify test stakeholders and software development lifecycle models that are relevant in a given context.",
	"Organize risk identification and risk assessment sessions within any software development lifecycle and use the results to guide testing to meet the test objectives.",
	"Define a project test strategy consistent with the organizational test strategy and project context.",
	"Continuously monitor and control testing to achieve project goals.",
	"Assess and report test progress to project stakeholders.",
	"Identify necessary skills and develop those skills within your team.",
	"Prepare and present a business case for testing in different contexts that outlines the costs and expected benefits.",
	"Lead test process improvement activities in projects or software development product streams and contribute to organizational test process improvement initiatives.",
	"Plan the test activities including the required test infrastructure and estimate the effort required to test.",
	"Create defect reports and a defect workflow suitable for a software development lifecycle.",
];

export default function TestManager3() {
	const { showBackToTop, scrollToTop } = PageScrollTop();

	const showBusinessOutcomes = businessOutcomesList.map((item, index) => {
		return <li key={index}>{item}</li>;
	});

	return (
		<>
			{/* Start Pagehelmet  */}
			<PageHelmet pageTitle="ISTQB Advanced Level Test Manager v3.0 [NEW!]" />
			{/* End Pagehelmet  */}

			<Header />

			{/* Start Breadcrump Area */}
			<div
				className="breadcrumb-area rn-bg-color pt--20 pb--60 bg_image bg_image--certifications"
				data-black-overlay="6"
			>
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="rn-page-title text-center pt--100">
								<h2 className="title theme-gradient">Advanced Level Test Manager</h2>
								<p>Certified Tester Advanced Level Test Manager (CTAL-TM) v3.0 [NEW!]</p>
								<div className="portfolio-button">
									<a
										className="rn-button-style--2 btn-solid-register"
										href="http://bit.ly/3jatMF3"
										target="_blank"
									>
										Register Now
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* End Breadcrump Area */}

			{/* Start Page Wrapper */}
			<div className="rn-service-details ptb--30 bg_color--1">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="service-details-inner">
								<div className="inner">
									{/* Start Single Area */}
									<div className="row sercice-details-content pb--20 align-items-center">
										<div className="col-lg-10 col-12">
											<div className="details mt_md--30 mt_sm--30">
												<h4 className="title">Overview</h4>
												<p>
													The ISTQB® Advanced Level Test Management (CTAL-TM)
													certification provides the knowledge and competencies
													to take responsibility for managing all the testing
													activities across the software development lifecycle.
													It covers everything from how to design a suitable test
													approach for the project based on the organizational
													test strategy to building a test team or testing
													competencies to complete the necessary testing.
												</p>

												<h4 className="title">Audience</h4>
												<p>
													This Advanced Level qualification is aimed at anyone
													involved in the management of software testing. This
													includes people in roles such as testers, test
													consultants, test managers, user acceptance testers,
													scrum masters, project managers or product owners. This
													Advanced Level Test Management qualification is also
													appropriate for anyone who wants an advanced
													understanding of software testing such as project
													managers, quality managers, software development
													managers, business analysts, IT directors and
													management consultants. Holders of the Advanced Level
													Test Management Certificate will be able to go on to
													the ISTQB® Expert Level software testing
													qualifications. The ISTQB® Certified Tester Advanced
													Level - Test Manager or Test Management certificate is
													valid for life and does not require renewal. The
													certificate is recognized internationally and
													demonstrates the candidates' professional competence
													and credibility in test management.
													<br />
													To gain this certification, candidates must hold the{" "}
													<a href="/certifications/certified-tester-foundation-level">
														<b>Certified Tester Foundation Level v4.0</b>
													</a>
													certificate or previous versions of the Foundation
													Level certificate and have sufficient practical
													experience. Please contact an ISTQB® Member Board or
													Exam Provider to determine the specific practical
													experience criteria.
												</p>
											</div>
										</div>
										<div className="col-lg-2 col-4">
											<div className="thumb">
												<img
													className="w-100 d-lg-block d-md-none"
													src={cttmLogo}
													alt="CTTM Logo"
													data-bs-toggle="modal"
													data-bs-target="#cttmLogoModal"
												/>
											</div>
										</div>
										{/* MODAL START*/}
										<div
											className="modal fade"
											id="cttmLogoModal"
											tabIndex="-1"
											aria-hidden="true"
										>
											<div className="modal-dialog">
												<div className="modal-content">
													<div className="modal-body">
														<button
															type="button"
															className="btn-close"
															data-bs-dismiss="modal"
															aria-label="Close"
														></button>
														<img
															className="w-100 d-lg-block d-md-none"
															src={cttmLogo}
														/>
													</div>
												</div>
											</div>
										</div>
										{/* MODAL END*/}
									</div>
									{/* End Single Area */}

									{/* Start Single Area */}
									<div className="row sercice-details-content pb--20 align-items-center">
										<div className="col-lg- col-12 order-2 order-lg-1">
											<div className="details mt_md--30 mt_sm--30">
												<h4 className="title">Contents</h4>
												<div className="thumb" style={{ paddingTop: "13px" }}>
													<img
														className="w-100"
														src={cttmTable}
														alt="CTTM Content Table"
													/>
												</div>
											</div>
										</div>
									</div>
									{/* End Single Area */}

									{/* Start Single Area */}
									<div className="row sercice-details-content pb--20 align-items-center">
										<div className="col-lg-12 col-10 order-2 order-lg-4">
											<div className="details mt_md--30 mt_sm--30">
												<h4 className="title">Exam Structure</h4>
												<ExamStructure exam="ctal-tm3" />
											</div>
										</div>
									</div>
									{/* End Single Area */}

									{/* Start Single Area */}
									<div className="row sercice-details-content pb--20 align-items-center">
										<div className="col-lg- col-12 order-2 order-lg-1">
											<div className="details mt_md--30 mt_sm--30">
												<h4 className="title">Business Outcomes</h4>
												<p>
													A candidate who has achieved the Certified Tester
													Advanced Level Test Management certification should be
													able to:
												</p>
												<ul className="liststyle">{showBusinessOutcomes}</ul>
											</div>
										</div>
									</div>
									{/* End Single Area */}

									{/* Start Single Area */}
									<div className="row sercice-details-content pb--20 align-items-center">
										<div className="col-lg- col-12 order-2 order-lg-1">
											<div className="details mt_md--30 mt_sm--30">
												<h4 className="title">More Information</h4>
												<p>
													Training is available from Accredited Training
													Providers (classroom, virtual, and e-learning). We
													highly recommend attending accredited training as it
													ensures that an ISTQB® Member Board has assessed the
													materials for relevance and consistency against the
													syllabus.
													<br />
													Self-study, using the syllabus and recommended reading
													material, is also an option when preparing for the
													exam.
													<br />
													<b>
														Holders of this certification may choose to
														proceed to other Core, Agile, or Specialist stream
														certifications.
													</b>
												</p>
											</div>
										</div>
									</div>
									{/* End Single Area */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* End Page Wrapper */}

			{/* Start Back To Top */}
			{showBackToTop && (
				<div className="backto-top" style={{ display: showBackToTop ? "block" : "none" }}>
					<div>
						<div onClick={scrollToTop} className="backto-top">
							<FiChevronUp />
						</div>
					</div>
				</div>
			)}
			{/* End Back To Top */}

			<Footer />
		</>
	);
}
