import PageHelmet from "../components/common/Helmet";
import Breadcrumb from "../components/common/Breadcrumb";
import { FiChevronUp } from "react-icons/fi";
import Header from "../components/header/HeaderThree";
import Footer from "../components/footer/Footer";
import PageScrollTop from "../components/pageScrollTop/PageScrollTop";

export default function AboutISTQB() {
	const { showBackToTop, scrollToTop } = PageScrollTop();

	return (
		<>
			<PageHelmet pageTitle="About ISTQB" />

			<Header />
			{/* Start Breadcrump Area */}
			<Breadcrumb title={"About ISQTB"} page={"about-istqb"} />
			{/* End Breadcrump Area */}

			{/* Start About Area  */}
			<div className="rn-about-area ptb--60 bg_color--1">
				<div className="rn-about-wrapper">
					<div className="container">
						<div className="row row--35 align-items-center">
							<div className="col-lg-12">
								<div className="about-inner inner">
									<div
										className="section-title"
										style={{
											borderBottom: "0.7px solid",
											marginBottom: "20px",
										}}
									>
										<h2 className="title">About ISTQB</h2>
									</div>
									<div className="about-us-list">
										<p>
											<b>
												ISTQB® is the leading global certification scheme
												in the field of software testing.
											</b>{" "}
											<br />
											As of June 2023, ISTQB® has administered over 1.2
											million exams and issued more than 914k certifications
											in over 130 countries. With its extensive network of
											Accredited Training Providers, Member Boards, and Exam
											Providers, ISTQB® is one of the biggest and most
											established vendor-neutral professional certification
											schemes in the world. ISTQB® terminology is industry
											recognized as the defacto language in the field of
											software testing and connects professionals worldwide.
										</p>
									</div>

									<div className="row mt--30">
										<div className="about-us-list mb--60">
											<h3 className="title">What we do</h3>
											<p>
												ISTQB® established, and continues to evolve, the
												internationally-recognized Certified Tester scheme
												which is a portfolio of certifications that
												develop, extend, and validate testing skills. The
												scheme is built around syllabi created by a global
												network of experts, the ISTQB® Glossary, sample
												exams, and the Testing Body of Knowledge (TBOK).
												<br />
												The Certified Tester Foundation Level
												certification provides testing professionals with
												essential terminology and a breadth of knowledge.
												It is the prerequisite to the other modules within
												the scheme which offer depth and specialization.
											</p>
										</div>

										<div className="about-us-list mb--60">
											<h3 className="title">Why ISTQB</h3>
											<ul className="list-style">
												<li>
													{" "}
													<b>High quality syllabi:</b> All syllabi are
													developed and reviewed by leading testing
													professionals from academia and industry.
												</li>
												<li>
													<b>Global recognition:</b> Certifications are
													recognized globally because of the consistent
													application of ISTQB® policies and procedures
													by the member boards.
												</li>
												<li>
													<b>Common language:</b> The ISTQB® Glossary
													provides a common vocabulary for the
													profession.
												</li>
												<li>
													<b>Objectivity:</b> The assessment of tester
													capabilities is conducted independently by
													ISTQB® and provides objective verification of
													competencies.
												</li>
												<li>
													<b>
														Encourage adherence to a Code of Ethics:
													</b>{" "}
													All ISTQB® certified testers are recommended
													to adhere to the Code of Ethics defined by
													ISTQB®.
												</li>
												<li>
													<b>Public Availability: </b> ISTQB® glossary
													and syllabi are made freely available on the
													ISTQB® website and on the Member Board
													websites in local languages.
												</li>
												<li>
													<b>Openness:</b> ISTQB® materials are
													developed on a voluntary basis and are open
													to contributions from anybody interested in
													joining ISTQB® working groups
												</li>
												<li>
													<b>Independence:</b> The not for profit
													nature of ISTQB® ensures that content is not
													constrained by particular methodologies or
													technologies, and is able to draw on best
													practices from a wide variety of sources.
												</li>
												<li>
													<b>Continuous improvement:</b> Syllabi and
													other documents are continuously being
													improved to meet the needs of business
													organizations around the world and to keep
													pace with the development of the profession.
												</li>
												<li>
													<b>Professional standing:</b> Being certified
													provides advantages for individuals and
													organizations by ensuring that testing
													professionals meet the high standards set by
													the ISTQB®.
												</li>
											</ul>
										</div>

										<div className="about-us-list mb--60">
											<h3 className="title">Who we are</h3>
											<p>
												ISTQB® was established in 1998 and its Certified
												Tester scheme has grown to be the leading software
												testing certification scheme worldwide. ISTQB®
												continues to strive to meet its vision and mission
												to support the testing community and promote
												software quality.
											</p>
											<h4 className="title mt--40">ISTQB® Vision</h4>
											<p>
												<b>
													To continually improve and advance the
													software testing profession by:
												</b>
												<br />
												Defining and maintaining a Body of Knowledge which
												allows testers to be certified based on best
												practices, connecting the international software
												testing community, and encouraging research.
											</p>
											<h4 className="title mt--40">ISTQB® Mission</h4>
											<ul className="list-style">
												<li>
													We promote the value of software testing as a
													profession to individuals and organizations.
												</li>
												<li>
													We help software testers to be more efficient
													and effective in their work, through the
													certification of competencies.
												</li>
												<li>
													We enable testers to progress their career
													through a Professionals Code of Ethics and a
													multi-level certification pathway that
													provides them with the skills and knowledge
													they need to fulfil their growing
													responsibilities and to achieve increased
													professionalism.
												</li>
												<li>
													We continually advance the Testing Body of
													Knowledge by drawing on the best available
													industry practices and the most innovative
													research, and we make this knowledge freely
													available to all.
												</li>
												<li>
													We set the criteria for accrediting training
													providers, to ensure consistent delivery of
													the Body of Knowledge, world-wide.
												</li>
												<li>
													We regulate the content and coverage of exam
													questions, the examination process, and the
													issuing of certifications by official
													examination bodies.
												</li>
												<li>
													We are committed to expanding software
													testing certifications around the world, by
													admitting member boards into the ISTQB®.
													These boards adhere to the constitution,
													bylaws, and processes defined by the ISTQB®,
													and participate in regular audits.
												</li>
												<li>
													We nurture an open international community,
													committed to sharing knowledge, ideas, and
													innovations in software testing.
												</li>
												<li>
													We foster relationships with academia,
													government, media, professional associations
													and other interested parties.
												</li>
												<li>
													We provide a reference point against which
													the effectiveness of testing services can be
													evaluated, by maintaining our prominence as a
													respected source of knowledge in software
													testing.
												</li>
											</ul>

											<h4 className="title mt--40">ISTQB® Values</h4>
											<ul className="list-style">
												<li>
													{" "}
													<b>Integrity:</b> We work together with
													honesty, transparency, and trust to serve the
													best interests of the testing community.
												</li>
												<li>
													<b>Diversity:</b> We are inclusive, treat
													each other as equals, and value different
													perspectives and experiences.
												</li>
												<li>
													<b>Innovation:</b> We inspire each other to
													share insight and embrace new ideas, and
													continuously evolve to deliver future value.
												</li>
												<li>
													<b>Passion:</b> We are volunteers who believe
													in what we do and work with purpose and pride
													to advance the field of testing.
												</li>
												<li>
													<b>Quality:</b> We strive to consistently
													offer a portfolio that is trusted as relevant
													and useful for current and future market
													needs.
												</li>
												<li>
													<b>International collaboration: </b> We
													believe in the strength of global
													representation and commit to democratic
													decision making via a General Assembly of
													Member Boards.
												</li>
											</ul>
										</div>
										<div className="about-us-list mb--60">
											<h3 className="title">Code of Ethics</h3>
											<p>
												ISTQB®, like other professional bodies including
												the ACM and IEEE, advocates for a code of ethics
												as part of our commitment to the profession.
											</p>
											<p>The ISTQB® defines the following code of ethics:</p>
											<ul className="list-style">
												<li>
													<b>PUBLIC</b> - Certified software testers
													shall act consistently in the public
													interest.
												</li>
												<li>
													<b>CLIENT AND EMPLOYER</b> - Certified
													software testers shall act in a manner that
													is in the best interests of their client and
													employer, consistent with the public
													interest.
												</li>
												<li>
													<b>PRODUCT</b> - Certified software testers
													shall ensure that the deliverables they
													provide (on the products and systems they
													test) meet the highest professional standards
													possible.
												</li>
												<li>
													<b>JUDGMENT</b> - Certified software testers
													shall maintain integrity and independence in
													their professional judgment.
												</li>
												<li>
													<b>MANAGEMENT</b> - Certified software test
													managers and leaders shall subscribe to and
													promote an ethical approach to the management
													of software testing.
												</li>
												<li>
													<b>PROFESSION</b> - Certified software
													testers shall advance the integrity and
													reputation of the profession consistent with
													the public interest.
												</li>
												<li>
													<b>COLLEAGUES</b> - Certified software
													testers shall be fair to and supportive of
													their colleagues, and promote cooperation
													with software developers.
												</li>
												<li>
													<b>SELF</b>- Certified software testers shall
													participate in lifelong learning regarding
													the practice of their profession and shall
													promote an ethical approach to the practice
													of the profession.
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* End About Area  */}

			{/* Start Back To Top */}
			{showBackToTop && (
				<div className="backto-top" style={{ display: showBackToTop ? "block" : "none" }}>
					<div>
						<div onClick={scrollToTop} className="backto-top">
							<FiChevronUp />
						</div>
					</div>
				</div>
			)}
			{/* End Back To Top */}

			<Footer />
		</>
	);
}
