import ctfl_3_1_Logo from "../../assets/images/logos/ctfl_3.1_logo.png";
import ctfl_4_Logo from "../../assets/images/logos/ctfl_4.0_logo.png";
import ctatLogo from "../../assets/images/logos/ctfl_at_logo.png";
import cttm_2012_Logo from "../../assets/images/logos/ctal_tm_logo.png";
import cttm_3_Logo from "../../assets/images/logos/ctal_tm_3.0_logo.png";
import cttaLogo from "../../assets/images/logos/ctal_ta_logo.png";
import ctttaLogo from "../../assets/images/logos/ctal_tta_logo.png";
import ctaiLogo from "../../assets/images/logos/ct_ai_logo.png";
import ctgtLogo from "../../assets/images/logos/ct_gt_logo.png";
import ctmatLogo from "../../assets/images/logos/ct_mat_logo.png";
import ctsecLogo from "../../assets/images/logos/ct_sec_logo.png";
import cttaeLogo from "../../assets/images/logos/ct_tae_logo.png";

const foundationLevelList = [
	{
		icon: ctfl_4_Logo,
		path: "/certifications/certified-tester-foundation-level",
		title: "ISTQB Foundation Level Certified Tester v4.0 [NEW!]",
		description:
			"The ISTQB® Certified Tester Foundation Level (CTFL) certification is the cornerstone of essential testing knowledge that can be applied to real-world scenarios. The syllabus provides a comprehensive understanding of the terminology and concepts used in the testing domain worldwide, making it relevant for all software delivery approaches and practices, including Waterfall, Agile, DevOps, and Continuous Delivery. CTFL certification is recognized as a prerequisite to all other ISTQB® certifications where Foundation Level is required.",
	},
	{
		icon: ctfl_3_1_Logo,
		path: "/certifications/certified-tester-foundation-level-v3-1",
		title: "ISTQB Foundation Level Certified Tester v3.1 [Retired]",
		description:
			"The ISTQB® Certified Tester Foundation Level (CTFL) certification is the cornerstone of essential testing knowledge that can be applied to real-world scenarios. The syllabus provides a comprehensive understanding of the terminology and concepts used in the testing domain worldwide, making it relevant for all software delivery approaches and practices, including Waterfall, Agile, DevOps, and Continuous Delivery. CTFL certification is recognized as a prerequisite to all other ISTQB® certifications where Foundation Level is required.",
	},
];
const advancedLevelList = [
	{
		icon: cttaeLogo,
		path: "/certifications/test-automation-engineer",
		title: "ISTQB Specialist Test Automation Engineer v2.0 [NEW!]",
		description:
			"The Certified Tester Advanced Level Test Automation Engineering (CTAL-TAE) qualification is aimed at anyone involved in software testing and test automation. This includes people in roles such as testers, test analysts, test automation engineers, test consultants, test architects, test managers, and software developers. This qualification is also appropriate for anyone who wants a basic understanding of test automation, such as project managers, quality managers, software development managers, business analysts, IT directors and management consultants.",
	},
	{
		icon: cttm_3_Logo,
		path: "/certifications/test-manager",
		title: "ISTQB Advanced Level Test Manager v4.0 [NEW!]",
		description:
			"The ISTQB® Advanced Level Test Manager (CTAL-TM) certification provides the knowledge and competencies to take responsibility for managing all the testing activities across the software development lifecycle. It covers everything from how to design a suitable test approach for the project based on the organizational test strategy to building a test team or testing competencies to complete the necessary testing.",
	},
	{
		icon: cttm_2012_Logo,
		path: "/certifications/test-manager-2012",
		title: "ISTQB Advanced Level Test Manager 2012 [Retiring]",
		description:
			"The ISTQB® Advanced Level Test Manager (CTAL-TM) certification provides the knowledge and competencies to take responsibility for managing all the testing activities across the software development lifecycle. It covers everything from how to design a suitable test approach for the project based on the organizational test strategy to building a test team or testing competencies to complete the necessary testing.",
	},
	{
		icon: cttaLogo,
		path: "/certifications/test-analyst",
		title: "ISTQB Advanced Level Test Analyst",
		description:
			"The ISTQB® Advanced Level Test Analyst (CTAL-TA) certification provides the skills needed to perform structured and thorough software testing across the software development life cycle. It goes into detail about the test analyst’s role and responsibilities in every step of a standard test process and expands on important test techniques.",
	},
	{
		icon: ctttaLogo,
		path: "/certifications/technical-test-analyst",
		title: "ISTQB Advanced Level Technical Test Analyst",
		description:
			"The ISTQB® Advanced Level Technical Test Analyst (CTAL-TTA) provides a thorough introduction to the technical testing skills which are fundamental in many organizations today. These skills include risk-based testing, white box testing, static and dynamic analysis, non-functional testing, and test automation.",
	},
];
const agileList = [
	{
		icon: ctatLogo,
		path: "/certifications/agile-tester",
		title: "ISTQB Foundation Level Agile Tester",
		description:
			"The ISTQB® Foundation Level Agile Tester (CTFL-AT) certification provides the key testing skills necessary to successfully contribute to an Agile project. CTFL-AT covers agile testing in alignment with the principles of agile software development as outlined in the Agile Manifesto. A tester on an Agile project will work differently than one working on a traditional project. Testers must understand the values and principles that underpin Agile projects, and how testers are an integral part of a whole-team approach together with developers and business representatives.",
	},
];
const specialistLevelList = [
	{
		icon: ctaiLogo,
		path: "/certifications/artificial-inteligence-tester",
		title: "ISTQB Certified Tester AI Testing",
		description:
			"The ISTQB® AI Testing (CT-AI) certification extends understanding of artificial intelligence and/or deep (machine) learning, most specifically testing AI-based systems and using AI in testing.",
	},
	{
		icon: ctgtLogo,
		path: "/certifications/gambling-tester",
		title: "ISTQB Specialist Gambling Industry Tester",
		description:
			"The ISTQB® Gambling Industry Tester (CT-GT) certification covers the key concepts in the gambling industry, the gambling industry ecosystem, and the different test types common to the gambling industry. It includes aspects such as gambling industry compliance testing, fun factor or player perspective testing, math testing, audio testing, multiplayer testing, and interoperability testing.",
	},
	{
		icon: ctmatLogo,
		path: "/certifications/mobile-tester",
		title: "ISTQB Specialist Mobile Application Tester",
		description:
			"The ISTQB® Mobile Application Testing (CT-MAT) certification provides an insight into methods, techniques, and tools a professional may use to test mobile applications. It covers the required mobile project activities, roles, methods, and methodologies.",
	},
	{
		icon: ctsecLogo,
		path: "/certifications/security-tester",
		title: "ISTQB Specialist Security Tester",
		description:
			"The ISTQB® Security Tester (CT-SEC) certification focuses on planning, performing, and evaluating security tests from multiple perspectives including risk, requirements, vulnerability, and human factors. It also covers security testing tools and standards.",
	},
	{
		icon: cttaeLogo,
		path: "/certifications/test-automation-engineer-2016",
		title: "ISTQB Specialist Test Automation Engineer 2016 [Retiring]",
		description:
			"The ISTQB® Test Automation Engineer (CT-TAE) certification focuses on the design, development, and maintenance of test automation solutions. It also covers the concepts, methods, tools, and processes for automating dynamic functional tests, and the relationship of those tests to test management, configuration management, defect management, software development processes, and quality assurance. Methods described are generally applicable across a variety of software life cycle approaches, types of software systems, and test types.",
	},
];

export default function CertificateListing({ type, column }) {
	const foundationLevelContent = foundationLevelList.slice(0, foundationLevelList.length);
	const advancedLevelContent = advancedLevelList.slice(0, advancedLevelList.length);
	const agileContent = agileList.slice(0, agileList.length);
	const specialistLevelContent = specialistLevelList.slice(0, specialistLevelList.length);

	if (type === "foundation") {
		return (
			<>
				<div className="row">
					{foundationLevelContent.map((val, i) => (
						<div className={`${column}`} key={i}>
							<a href={val.path}>
								<div className="service service__style--2 mt--0">
									<img
										src={val.icon}
										style={{
											width: "170px",
											height: "170px",
											marginTop: "-30px",
										}}
									/>
									<div className="content">
										<h3 className="title">{val.title}</h3>
										<p
											style={{
												textOverflow: "ellipsis",
												overflow: "hidden",
												display: "-webkit-box",
												WebkitLineClamp: 5,
												WebkitBoxOrient: "vertical",
											}}
										>
											{val.description}
										</p>
									</div>
								</div>
							</a>
						</div>
					))}
				</div>
			</>
		);
	} else if (type === "advanced") {
		return (
			<>
				<div className="row">
					{advancedLevelContent.map((val, i) => (
						<div className={`${column}`} key={i}>
							<a href={val.path}>
								<div className="service service__style--2">
									<img
										src={val.icon}
										style={{
											width: "170px",
											height: "170px",
											marginTop: "-30px",
										}}
									/>
									<div className="content">
										<h3 className="title">{val.title}</h3>
										<p
											style={{
												textOverflow: "ellipsis",
												overflow: "hidden",
												display: "-webkit-box",
												WebkitLineClamp: 5,
												WebkitBoxOrient: "vertical",
											}}
										>
											{val.description}
										</p>
									</div>
								</div>
							</a>
						</div>
					))}
				</div>
			</>
		);
	} else if (type === "agile") {
		return (
			<>
				<div className="row">
					{agileContent.map((val, i) => (
						<div className={`${column}`} key={i}>
							<a href={val.path}>
								<div className="service service__style--2">
									<img
										src={val.icon}
										style={{
											width: "170px",
											height: "170px",
											marginTop: "-30px",
										}}
									/>
									<div className="content">
										<h3 className="title">{val.title}</h3>
										<p
											style={{
												textOverflow: "ellipsis",
												overflow: "hidden",
												display: "-webkit-box",
												WebkitLineClamp: 5,
												WebkitBoxOrient: "vertical",
											}}
										>
											{val.description}
										</p>
									</div>
								</div>
							</a>
						</div>
					))}
				</div>
			</>
		);
	} else if (type === "specialist") {
		return (
			<>
				<div className="row">
					{specialistLevelContent.map((val, i) => (
						<div className={`${column}`} key={i}>
							<a href={val.path}>
								<div className="service service__style--2">
									<img
										src={val.icon}
										style={{
											width: "170px",
											height: "170px",
											marginTop: "-30px",
										}}
									/>
									<div className="content">
										<h3 className="title">{val.title}</h3>
										<p
											style={{
												textOverflow: "ellipsis",
												overflow: "hidden",
												display: "-webkit-box",
												WebkitLineClamp: 5,
												WebkitBoxOrient: "vertical",
											}}
										>
											{val.description}
										</p>
									</div>
								</div>
							</a>
						</div>
					))}
				</div>
			</>
		);
	}
}
