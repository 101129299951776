import PageHelmet from "../../components/common/Helmet";
import { FiChevronUp } from "react-icons/fi";
import Header from "../../components/header/HeaderThree";
import Footer from "../../components/footer/Footer";
import ctgtLogo from "../../assets/images/logos/ct_gt_logo.png";
import ctgtTable from "../../assets/images/tables/ct_gt_table.png";
import ExamStructure from "./ExamStructure";
import PageScrollTop from "../../components/pageScrollTop/PageScrollTop";

const businessOutcomesList = [
	"Promote efficient and effective communication by using a common vocabulary inside the gambling industry.",
	"Understand specific quality attributes that require testing within the gambling industry.",
	"Understand typical test practices by describing the standard software development and testing methodologies within the gambling industry.",
	"Understand gambling hardware and software certification which is the main difference between the gambling industry and other industries.",
	"Use established techniques for designing tests aligned with gambling specific needs.",
	"Appreciate the importance of jurisdictions and regulatory bodies in the gambling industry.",
];

export default function GamblingTester() {
	const { showBackToTop, scrollToTop } = PageScrollTop();

	const showBusinessOutcomes = businessOutcomesList.map((item, index) => {
		return <li key={index}>{item}</li>;
	});

	return (
		<>
			{/* Start Pagehelmet  */}
			<PageHelmet pageTitle="ISTQB Specialist Gambling Industry Tester" />
			{/* End Pagehelmet  */}

			<Header />

			{/* Start Breadcrump Area */}
			<div
				className="breadcrumb-area rn-bg-color pt--20 pb--60 bg_image bg_image--certifications"
				data-black-overlay="6"
			>
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="rn-page-title text-center pt--100">
								<h2 className="title theme-gradient">
									Specialist Gambling Industry Tester
								</h2>
								<p>Certified Tester Gambling Industry Tester (CT-GT)</p>
								<div className="portfolio-button">
									<a
										className="rn-button-style--2 btn-solid-register"
										href="http://bit.ly/3jatMF3"
										target="_blank"
									>
										Register Now
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* End Breadcrump Area */}

			{/* Start Page Wrapper */}
			<div className="rn-service-details ptb--30 bg_color--1">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="service-details-inner">
								<div className="inner">
									{/* Start Single Area */}
									<div className="row sercice-details-content pb--20 align-items-center">
										<div className="col-lg-10 col-12">
											<div className="details mt_md--30 mt_sm--30">
												<h4 className="title">Overview</h4>
												<p>
													The ISTQB® Gambling Industry Tester (CT-GT)
													certification covers the key concepts in the
													gambling industry, the gambling industry
													ecosystem, and the different test types
													common to the gambling industry. It includes
													aspects such as gambling industry compliance
													testing, fun factor or player perspective
													testing, math testing, audio testing,
													multiplayer testing, and interoperability
													testing.
												</p>

												<h4 className="title">Audience</h4>
												<p>
													The Gambling Industry Tester certification is
													aimed at anyone involved in software testing
													in the gambling industry including testers,
													test analysts, test engineers, test
													consultants, test managers, user acceptance
													testers, and software developers. It may also
													be useful to people in the role of project
													manager, quality manager, software
													development manager, system analyst (business
													analysts), IT manager, or management
													consultants, who wish to acquire a deeper
													understanding of software testing in the
													gambling industry.
													<br />
													To gain this certification, candidates must
													hold the{" "}
													<a href="/certifications/certified-tester-foundation-level">
														<b>
															Certified Tester Foundation Level{" "}
														</b>
													</a>
													certificate.
												</p>
											</div>
										</div>
										<div className="col-lg-2 col-4">
											<div className="thumb">
												<img
													className="w-100 d-lg-block d-md-none"
													src={ctgtLogo}
													alt="CTGT Logo"
													data-bs-toggle="modal"
													data-bs-target="#ctgtLogoModal"
												/>
											</div>
										</div>
										{/* MODAL START*/}
										<div
											className="modal fade"
											id="ctgtLogoModal"
											tabIndex="-1"
											aria-hidden="true"
										>
											<div className="modal-dialog">
												<div className="modal-content">
													<div className="modal-body">
														<button
															type="button"
															className="btn-close"
															data-bs-dismiss="modal"
															aria-label="Close"
														></button>
														<img
															className="w-100 d-lg-block d-md-none"
															src={ctgtLogo}
														/>
													</div>
												</div>
											</div>
										</div>
										{/* MODAL END*/}
									</div>
									{/* End Single Area */}

									{/* Start Single Area */}
									<div className="row sercice-details-content pb--20 align-items-center">
										<div className="col-lg- col-12 order-2 order-lg-1">
											<div className="details mt_md--30 mt_sm--30">
												<h4 className="title">Contents</h4>
												<div
													className="thumb"
													style={{ paddingTop: "13px" }}
												>
													<img
														className="w-100"
														src={ctgtTable}
														alt="CTGT Content Table"
													/>
												</div>
											</div>
										</div>
									</div>
									{/* End Single Area */}

									{/* Start Single Area */}
									<div className="row sercice-details-content pb--20 align-items-center">
										<div className="col-lg-12 col-10 order-2 order-lg-4">
											<div className="details mt_md--30 mt_sm--30">
												<h4 className="title">Exam Structure</h4>
												<ExamStructure exam="ct-gt" />
											</div>
										</div>
									</div>
									{/* End Single Area */}

									{/* Start Single Area */}
									<div className="row sercice-details-content pb--20 align-items-center">
										<div className="col-lg- col-12 order-2 order-lg-1">
											<div className="details mt_md--30 mt_sm--30">
												<h4 className="title">Business Outcomes</h4>
												<p>
													Candidates who have achieved the Foundation
													Level Gambling Industry Tester Specialist
													certification should be able to accomplish
													the following Business Objectives:
												</p>
												<ul className="liststyle">
													{showBusinessOutcomes}
													<p>
														In general, a Certified Foundation Level
														Gambling Industry Tester Specialist is
														expected to have acquired the necessary
														skills to working effectively within a
														Gambling Industry testing team and
														environment.
													</p>
												</ul>
											</div>
										</div>
									</div>
									{/* End Single Area */}

									{/* Start Single Area */}
									<div className="row sercice-details-content pb--20 align-items-center">
										<div className="col-lg- col-12 order-2 order-lg-1">
											<div className="details mt_md--30 mt_sm--30">
												<h4 className="title">More Information</h4>
												<p>
													Training is available from Accredited
													Training Providers (classroom, virtual, and
													e-learning). We highly recommend attending
													accredited training as it ensures that an
													ISTQB® Member Board has assessed the
													materials for relevance and consistency
													against the syllabus.
													<br />
													Self-study, using the syllabus and
													recommended reading material, is also an
													option when preparing for the exam.
													<br />
													<b>
														Holders of this certification may choose
														to proceed to other Core, Agile, or
														Specialist stream certifications.
													</b>
												</p>
											</div>
										</div>
									</div>
									{/* End Single Area */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* End Page Wrapper */}

			{/* Start Back To Top */}
			{showBackToTop && (
				<div className="backto-top" style={{ display: showBackToTop ? "block" : "none" }}>
					<div>
						<div onClick={scrollToTop} className="backto-top">
							<FiChevronUp />
						</div>
					</div>
				</div>
			)}
			{/* End Back To Top */}

			<Footer />
		</>
	);
}
