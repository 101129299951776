import Header from "../components/header/HeaderThree";
import Helmet from "../components/common/Helmet";
import { FiChevronUp } from "react-icons/fi";
import Footer from "../components/footer/Footer";
import CounterTwo from "../components/counters/CounterTwo";
import { NewsSlider } from "../components/newsSlider/NewsSlider";
import Team from "../components/team/Team";
import PageScrollTop from "../components/pageScrollTop/PageScrollTop";
import React, { useState, useEffect } from "react";

const title = "About ArmSTQB";

export default function ArmSTQBHome() {
	const { showBackToTop, scrollToTop } = PageScrollTop();
	const [marginTop, setMarginTop] = useState("20px");

	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth <= 1000) {
				setMarginTop("50px");
			} else {
				setMarginTop("10px");
			}
		};

		// Add event listener for window resize
		window.addEventListener("resize", handleResize);

		// Call handleResize initially to set the initial marginTop value
		handleResize();

		// Cleanup event listener
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return (
		<>
			<Helmet pageTitle="ArmSTQB" />
			<Header />
			{/* Start Banner Area  */}
			<div className="prv-banner-wrapper">
				<div className="container-fluid">
					<div className="plr--120">
						<div className="row">
							<div className="col-lg-12">
								<div>
									<h2
										className="title"
										style={{
											color: "white",
											marginTop,
										}}
									>
										Welcome to ArmSTQB®
									</h2>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="fullscreen empty-div gradient-overlay"></div>

			{/* Start Preview Main Wrapper */}
			<div className="main-wrapper" id="demo">
				{/* Start About Area  */}
				<div className="rn-about-area ptb--60 bg_color--1">
					<div className="rn-about-wrapper">
						<div className="container">
							<div className="row row--35 align-items-center">
								<div className="col-lg-12">
									<div className="about-inner inner">
										<div
											className="section-title"
											style={{
												borderBottom: "0.7px solid",
												marginBottom: "20px",
											}}
										>
											<h2 className="title">{title}</h2>
										</div>
										<div className="about-us-list">
											<p>
												ArmSTQB is the ISTQB national board of Armenia. It acts and
												represents the ISTQB mission in Armenia. ISTQB® is the
												leading global certification scheme in the field of software
												testing. As of June 2023, ISTQB® has administered 1.3
												million exams and issued more than 914k certifications in
												over 130 countries. With its extensive network of Accredited
												Training Providers, Member Boards, and Exam Providers,
												ISTQB® is one of the biggest and most established
												vendor-neutral professional certification schemes worldwide.
											</p>
											<p>
												ISTQB® terminology is industry-recognized as the defacto
												language in the field of software testing and connects
												professionals worldwide.
											</p>
											<p>
												Join our network of software testing professionals by
												becoming{" "}
												<span style={{ color: "blue", fontWeight: "bold" }}>
													<a href="/certifications" target="_blank">
														certified
													</a>
												</span>{" "}
												today!
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* End About Area  */}

				{/* Start CounterUp Area */}
				<div className="rn-counterup-area pb--120 pt--50 bg_color--1">
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="section-title text-center">
									<h3 className="fontWeight500">Our Fun Facts</h3>
								</div>
							</div>
						</div>
						<CounterTwo />
					</div>
				</div>
				{/* End CounterUp Area */}

				{/* Start Portfolio Area */}
				<div className="portfolio-area pb--120 bg_color--1" id="portfolio">
					<div className="container">
						<div className="row">
							<div className="col-lg-6">
								<div className="section-title text-left service-style--3 mb--30">
									<h2 className="title">News</h2>
									<p>Check out ArmSTQB's Latest News</p>
								</div>
							</div>
						</div>
					</div>
					<NewsSlider />
				</div>
				{/* End Portfolio Area */}

				{/* Start Team Area  */}
				<div className="rn-team-wrapper pb--60">
					<div className="rn-team-area">
						<div className="container">
							<div className="row">
								<div className="col-lg-12">
									<div className="section-title text-left mb--30">
										<h2>Meet our Board Members</h2>
									</div>
								</div>
							</div>
							<Team column="col-lg-3" teamStyle="" item="16" />
						</div>
					</div>
				</div>
				{/* End Team Area  */}
			</div>
			{/* End Preview Main Wrapper */}

			{/* Start Back To Top */}
			{showBackToTop && (
				<div className="backto-top" style={{ display: showBackToTop ? "block" : "none" }}>
					<div>
						<div onClick={scrollToTop} className="backto-top">
							<FiChevronUp />
						</div>
					</div>
				</div>
			)}
			{/* End Back To Top */}

			{/* Start Footer Area  */}
			<Footer />
			{/* End Footer Area  */}
		</>
	);
}
