import PageHelmet from "../../components/common/Helmet";
import { FiChevronUp } from "react-icons/fi";
import Header from "../../components/header/HeaderThree";
import Footer from "../../components/footer/Footer";
import ctaiLogo from "../../assets/images/logos/ct_ai_logo.png";
import ctaiTable from "../../assets/images/tables/ct_ai_table.png";
import ExamStructure from "./ExamStructure";
import PageScrollTop from "../../components/pageScrollTop/PageScrollTop";

const businessOutcomesList = [
	"Understand the current state and expected trends of AI.",
	"Contribute to the test strategy for an AI-Based system.",
	"Design and execute test cases for AI-based systems.",
	"Recognize the special requirements for the test infrastructure to support the testing of AI-based systems.",
	"Understand how AI can be used to support software testing.",
];

export default function ArtificialInteligenceTester() {
	const { showBackToTop, scrollToTop } = PageScrollTop();

	const showBusinessOutcomes = businessOutcomesList.map((item, index) => {
		return <li key={index}>{item}</li>;
	});

	return (
		<>
			{/* Start Pagehelmet  */}
			<PageHelmet pageTitle="ISTQB Certified Tester AI Testing" />
			{/* End Pagehelmet  */}

			<Header />

			{/* Start Breadcrump Area */}
			<div
				className="breadcrumb-area rn-bg-color pt--20 pb--60 bg_image bg_image--certifications"
				data-black-overlay="6"
			>
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="rn-page-title text-center pt--100">
								<h2 className="title theme-gradient">Specialist Tester AI Testing</h2>
								<p>Certified Tester AI Testing (CT-AI)</p>
								<div className="portfolio-button">
									<a
										className="rn-button-style--2 btn-solid-register"
										href="http://bit.ly/3jatMF3"
										target="_blank"
									>
										Register Now
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* End Breadcrump Area */}

			{/* Start Page Wrapper */}
			<div className="rn-service-details ptb--30 bg_color--1">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="service-details-inner">
								<div className="inner">
									{/* Start Single Area */}
									<div className="row sercice-details-content pb--20 align-items-center">
										<div className="col-lg-10 col-12">
											<div className="details mt_md--30 mt_sm--30">
												<h4 className="title">Overview</h4>
												<p>
													The ISTQB® AI Testing (CT-AI) certification
													extends understanding of artificial
													intelligence and/or deep (machine) learning,
													most specifically testing AI-based systems
													and using AI in testing.
												</p>

												<h4 className="title">Audience</h4>
												<p>
													The Certified Tester AI Testing certification
													is aimed at anyone involved in testing
													AI-based systems and/or AI for testing. This
													includes people in roles such as testers,
													test analysts, data analysts, test engineers,
													test consultants, test managers, user
													acceptance testers, and software developers.
													This certification is also appropriate for
													anyone who wants a basic understanding of
													testing AI-based systems and/or AI for
													testing, such as project managers, quality
													managers, software development managers,
													business analysts, operations team members,
													IT directors, and management consultants.
													<br />
													To gain this certification, candidates must
													hold the{" "}
													<a href="/certifications/certified-tester-foundation-level">
														<b>
															Certified Tester Foundation Level{" "}
														</b>
													</a>
													certificate.
												</p>
											</div>
										</div>
										<div className="col-lg-2 col-4">
											<div className="thumb">
												<img
													className="w-100 d-lg-block d-md-none"
													src={ctaiLogo}
													alt="CTAI Logo"
													data-bs-toggle="modal"
													data-bs-target="#ctaiLogoModal"
												/>
											</div>
										</div>
										{/* MODAL START*/}
										<div
											className="modal fade"
											id="ctaiLogoModal"
											tabIndex="-1"
											aria-hidden="true"
										>
											<div className="modal-dialog">
												<div className="modal-content">
													<div className="modal-body">
														<button
															type="button"
															className="btn-close"
															data-bs-dismiss="modal"
															aria-label="Close"
														></button>
														<img
															className="w-100 d-lg-block d-md-none"
															src={ctaiLogo}
														/>
													</div>
												</div>
											</div>
										</div>
										{/* MODAL END*/}
									</div>
									{/* End Single Area */}

									{/* Start Single Area */}
									<div className="row sercice-details-content pb--20 align-items-center">
										<div className="col-lg- col-12 order-2 order-lg-1">
											<div className="details mt_md--30 mt_sm--30">
												<h4 className="title">Contents</h4>
												<p>
													The following image demonstrates the contents
													of the AI Testing syllabus which is part of
													the ISTQB® Specialist stream:
												</p>
												<div
													className="thumb"
													style={{ paddingTop: "13px" }}
												>
													<img
														className="w-100"
														src={ctaiTable}
														alt="CTAI Content Table"
													/>
												</div>
											</div>
										</div>
									</div>
									{/* End Single Area */}

									{/* Start Single Area */}
									<div className="row sercice-details-content pb--20 align-items-center">
										<div className="col-lg-12 col-10 order-2 order-lg-4">
											<div className="details mt_md--30 mt_sm--30">
												<h4 className="title">Exam Structure</h4>
												<ExamStructure exam="ct-ai" />
											</div>
										</div>
									</div>
									{/* End Single Area */}

									{/* Start Single Area */}
									<div className="row sercice-details-content pb--20 align-items-center">
										<div className="col-lg- col-12 order-2 order-lg-1">
											<div className="details mt_md--30 mt_sm--30">
												<h4 className="title">Business Outcomes</h4>
												<p>
													Individuals who hold the ISTQB® Certified
													Tester- AI Testing certification should be
													able to accomplish the following business
													outcomes:
												</p>
												<ul className="liststyle">
													{showBusinessOutcomes}
												</ul>
											</div>
										</div>
									</div>
									{/* End Single Area */}

									{/* Start Single Area */}
									<div className="row sercice-details-content pb--20 align-items-center">
										<div className="col-lg- col-12 order-2 order-lg-1">
											<div className="details mt_md--30 mt_sm--30">
												<h4 className="title">More Information</h4>
												<p>
													Training is available from Accredited
													Training Providers (classroom, virtual, and
													e-learning). We highly recommend attending
													accredited training as it ensures that an
													ISTQB® Member Board has assessed the
													materials for relevance and consistency
													against the syllabus.
													<br />
													Self-study, using the syllabus and
													recommended reading material, is also an
													option when preparing for the exam.
													<br />
													<b>
														Holders of this certification may choose
														to proceed to other Core, Agile, or
														Specialist stream certifications.
													</b>
												</p>
											</div>
										</div>
									</div>
									{/* End Single Area */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* End Page Wrapper */}

			{/* Start Back To Top */}
			{showBackToTop && (
				<div className="backto-top" style={{ display: showBackToTop ? "block" : "none" }}>
					<div>
						<div onClick={scrollToTop} className="backto-top">
							<FiChevronUp />
						</div>
					</div>
				</div>
			)}
			{/* End Back To Top */}

			<Footer />
		</>
	);
}
