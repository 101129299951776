import PageHelmet from "../../../components/common/Helmet";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../../../components/header/HeaderThree";
import Footer from "../../../components/footer/Footer";
import exam2024 from "../../../assets/images/events/events_15_01_2024/2024-exams.jpg";
import PageScrollTop from "../../../components/pageScrollTop/PageScrollTop";

export default function ExamsEvent() {
	const { showBackToTop, scrollToTop } = PageScrollTop();

	return (
		<>
			{/* Start Pagehelmet  */}
			<PageHelmet pageTitle="ISTQB certification exam of 2024" />
			{/* End Pagehelmet  */}

			<Header />

			{/* Start Breadcrump Area */}
			<div className="rn-page-title-area pt--20 pb--60" data-black-overlay="7">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="rn-page-title text-center pt--100">
								<h2 className="title theme-gradient">
									ISTQB certification exams of 2024
								</h2>
								<p>15/01/2024</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* End Breadcrump Area */}

			{/* Start Page Wrapper */}
			<div className="rn-service-details ptb--30 bg_color--1">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="service-details-inner">
								<div className="inner">
									{/* Start Single Area */}
									<div className="row sercice-details-content pb--20 align-items-center">
										<div className="col-lg-6 col-12">
											<div className="details mt_md--30 mt_sm--30">
												<h4 className="title">Overview</h4>
												<p>
													During the registration, you can choose your
													desired exam day. If the announced schedule
													is inconvenient, please call to settle the
													matter.
												</p>
												<p>
													The exam is paper-based and is in English.
													You'll have to register for participation,
													after which you will receive a confirmation
													letter. It will contain information about the
													exam and describe the payment procedure.
												</p>
												<p>
													For more information, click on{" "}
													<a
														style={{ color: "red" }}
														href="http://bit.ly/3jatMF3"
														target="_blank"
													>
														Register Now{" "}
													</a>
													Button.
												</p>
											</div>
										</div>
										<div className="col-lg-6 col-12">
											<img
												className="w-100 d-lg-block d-md-none mt--30"
												src={exam2024}
												alt="2024 Exams Poster"
											/>
											<div
												className="portfolio-button mt--40"
												style={{
													alignItems: "center",
													display: "flex",
													justifyContent: "center",
												}}
											>
												<a
													className="rn-button-style--2 btn-solid"
													href="http://bit.ly/3jatMF3"
													target="_blank"
												>
													Register Now
												</a>
											</div>
										</div>
									</div>
									{/* End Single Area */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* End Page Wrapper */}

			{/* Start Back To Top */}
			{showBackToTop && (
				<div className="backto-top" style={{ display: showBackToTop ? "block" : "none" }}>
					<div>
						<div onClick={scrollToTop} className="backto-top">
							<FiChevronUp />
						</div>
					</div>
				</div>
			)}
			{/* End Back To Top */}

			<Footer />
		</>
	);
}
