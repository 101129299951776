import PageHelmet from "../../../components/common/Helmet";
import { FiChevronUp } from "react-icons/fi";
import Header from "../../../components/header/HeaderThree";
import Footer from "../../../components/footer/Footer";
import syllabi from "../../../assets/images/news/news_23_01_2024/ai-exam-syllabi.jpg";
import PageScrollTop from "../../../components/pageScrollTop/PageScrollTop";

export default function AIExamNews() {
	const { showBackToTop, scrollToTop } = PageScrollTop();

	return (
		<>
			{/* Start Pagehelmet  */}
			<PageHelmet pageTitle="ISTQB® AI Testing Specialist exam is now available in ArmSTQB" />
			{/* End Pagehelmet  */}

			<Header />

			{/* Start Breadcrump Area */}
			<div className="rn-page-title-area pt--20 pb--60" data-black-overlay="7">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="rn-page-title text-center pt--100">
								<h2 className="title theme-gradient">
									ISTQB® AI Testing Specialist exam is now available in ArmSTQB
								</h2>
								<p>23/01/2024</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* End Breadcrump Area */}

			{/* Start Page Wrapper */}
			<div className="rn-service-details ptb--30 bg_color--1">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="service-details-inner">
								<div className="inner">
									{/* Start Single Area */}
									<div className="row sercice-details-content pb--20 align-items-center">
										<div className="col-lg-6 col-12">
											<div className="details mt_md--30 mt_sm--30">
												<h4 className="title">Overview</h4>
												<p>
													The ISTQB® AI Testing (CT-AI) certification
													extends understanding of artificial
													intelligence and machine learning, most
													specifically testing AI-based systems and
													using AI in testing.
												</p>
												<p>
													The Certified Tester AI Testing certification
													is aimed at anyone involved in testing
													AI-based systems and AI for testing. This
													includes people in roles such as testers,
													test analysts, data analysts, test engineers,
													test consultants, test managers, user
													acceptance testers, and software developers.
													This certification is also appropriate for
													anyone who wants a basic understanding of
													testing AI-based systems and AI for testing.
													<br />
													To gain this certification, candidates must
													hold the Certified Tester Foundation Level
													certificate.
												</p>
												<p>
													🔍 Exam Structure
													<br />
													📝 No. of Questions: 40
													<br />
													⏱ Exam Length: 1 hour 15 minutes
													<br />
													📩 Exam registration is available through the
													link:{" "}
													<a
														href="http://bit.ly/3jatMF3"
														target="_blank"
													>
														http://bit.ly/3jatMF3
													</a>
													<br />
													📌 To access additional information,{" "}
													<a
														style={{ color: "red" }}
														href="/certifications/artificial-inteligence-tester"
														target="_blank"
													>
														click here
													</a>
													.
												</p>
											</div>
										</div>
										<div className="col-lg-6 col-12">
											<img
												className="w-100 d-lg-block d-md-none mt--30"
												src={syllabi}
												alt="CTAI Syllabi"
												data-bs-toggle="modal"
												data-bs-target="#ctaiSyllabiModal"
											/>
										</div>
										{/* MODAL START*/}
										<div
											className="modal fade"
											id="ctaiSyllabiModal"
											tabIndex="-1"
											aria-hidden="true"
										>
											<div
												style={{
													width: "auto",
													height: "auto",
													maxWidth: "100%",
													maxHeight: "100%",
													display: "block",
													margin: "auto",
												}}
												className="modal-dialog"
											>
												<div className="modal-content">
													<div
														style={{
															width: "auto",
															height: "auto",
															maxWidth: "100%",
															maxHeight: "100%",
															display: "block",
															margin: "auto",
														}}
														className="modal-body"
													>
														<button
															type="button"
															className="btn-close"
															data-bs-dismiss="modal"
															aria-label="Close"
														></button>
														<img
															style={{
																width: "auto",
																height: "auto",
																maxWidth: "100%",
																maxHeight: "100%",
																display: "block",
																margin: "auto",
															}}
															// className="w-100 d-lg-block d-md-none"
															src={syllabi}
														/>
													</div>
												</div>
											</div>
										</div>
										{/* MODAL END */}
									</div>
									{/* End Single Area */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* End Page Wrapper */}

			{/* Start Back To Top */}
			{showBackToTop && (
				<div className="backto-top" style={{ display: showBackToTop ? "block" : "none" }}>
					<div>
						<div onClick={scrollToTop} className="backto-top">
							<FiChevronUp />
						</div>
					</div>
				</div>
			)}
			{/* End Back To Top */}

			<Footer />
		</>
	);
}
