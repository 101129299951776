import PageHelmet from "../../components/common/Helmet";
import { FiChevronUp } from "react-icons/fi";
import Header from "../../components/header/HeaderThree";
import Footer from "../../components/footer/Footer";
import ctmatLogo from "../../assets/images/logos/ct_mat_logo.png";
import ctmatTable from "../../assets/images/tables/ct_mat_table.png";
import ExamStructure from "./ExamStructure";
import PageScrollTop from "../../components/pageScrollTop/PageScrollTop";

const businessOutcomesList = [
	"Understand and review business and technology drivers for mobile apps in order to create a test strategy.",
	"Identify and understand the key challenges, risks and expectations associated with testing a mobile application.",
	"Apply test types and levels specific to mobile applications.",
	"Apply common test types, such as those mentioned in ISTQB® Certified Tester Foundation Level syllabus 2018, in the mobile specific context.",
	"Carry out the activities required specifically for mobile application testing as part of the main activities of the ISTQB® test process.",
	"Identify and use suitable environments and appropriate tools for mobile application testing.",
	"Understand methods and tools specifically to support mobile application test automation.",
];

export default function MobileTester() {
	const { showBackToTop, scrollToTop } = PageScrollTop();

	const showBusinessOutcomes = businessOutcomesList.map((item, index) => {
		return <li key={index}>{item}</li>;
	});

	return (
		<>
			{/* Start Pagehelmet  */}
			<PageHelmet pageTitle="ISTQB Specialist Mobile Application Tester" />
			{/* End Pagehelmet  */}

			<Header />

			{/* Start Breadcrump Area */}
			<div
				className="breadcrumb-area rn-bg-color pt--20 pb--60 bg_image bg_image--certifications"
				data-black-overlay="6"
			>
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="rn-page-title text-center pt--100">
								<h2 className="title theme-gradient">
									Specialist Mobile Application Tester
								</h2>
								<p>Certified Tester Mobile Application Testing (CT-MAT)</p>
								<div className="portfolio-button">
									<a
										className="rn-button-style--2 btn-solid-register"
										href="http://bit.ly/3jatMF3"
										target="_blank"
									>
										Register Now
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* End Breadcrump Area */}

			{/* Start Page Wrapper */}
			<div className="rn-service-details ptb--30 bg_color--1">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="service-details-inner">
								<div className="inner">
									{/* Start Single Area */}
									<div className="row sercice-details-content pb--20 align-items-center">
										<div className="col-lg-10 col-12">
											<div className="details mt_md--30 mt_sm--30">
												<h4 className="title">Overview</h4>
												<p>
													The ISTQB® Mobile Application Testing
													(CT-MAT) certification provides an insight
													into methods, techniques, and tools a
													professional may use to test mobile
													applications. It covers the required mobile
													project activities, roles, methods, and
													methodologies.
												</p>

												<h4 className="title">Audience</h4>
												<p>
													The Mobile Application Testing certification
													is intended for professionals who are working
													with mobile technology. It is also for
													professionals who are planning to start
													implementing mobile projects or are working
													within companies that plan to do so.
													<br />
													To gain this certification, candidates must
													hold the{" "}
													<a href="/certifications/certified-tester-foundation-level">
														<b>
															Certified Tester Foundation Level{" "}
														</b>
													</a>
													certificate.
												</p>
											</div>
										</div>
										<div className="col-lg-2 col-4">
											<div className="thumb">
												<img
													className="w-100 d-lg-block d-md-none"
													src={ctmatLogo}
													alt="CTMAT Logo"
													data-bs-toggle="modal"
													data-bs-target="#ctmatLogoModal"
												/>
											</div>
										</div>

										{/* MODAL START*/}
										<div
											className="modal fade"
											id="ctmatLogoModal"
											tabIndex="-1"
											aria-hidden="true"
										>
											<div className="modal-dialog">
												<div className="modal-content">
													<div className="modal-body">
														<button
															type="button"
															className="btn-close"
															data-bs-dismiss="modal"
															aria-label="Close"
														></button>
														<img
															className="w-100 d-lg-block d-md-none"
															src={ctmatLogo}
														/>
													</div>
												</div>
											</div>
										</div>
										{/* MODAL END*/}
									</div>
									{/* End Single Area */}

									{/* Start Single Area */}
									<div className="row sercice-details-content pb--20 align-items-center">
										<div className="col-lg- col-12 order-2 order-lg-1">
											<div className="details mt_md--30 mt_sm--30">
												<h4 className="title">Contents</h4>
												<div
													className="thumb"
													style={{ paddingTop: "13px" }}
												>
													<img
														className="w-100"
														src={ctmatTable}
														alt="CTMAT Content Table"
													/>
												</div>
											</div>
										</div>
									</div>
									{/* End Single Area */}

									{/* Start Single Area */}
									<div className="row sercice-details-content pb--20 align-items-center">
										<div className="col-lg-12 col-10 order-2 order-lg-4">
											<div className="details mt_md--30 mt_sm--30">
												<h4 className="title">Exam Structure</h4>
												<ExamStructure exam="ct-mat" />
											</div>
										</div>
									</div>
									{/* End Single Area */}

									{/* Start Single Area */}
									<div className="row sercice-details-content pb--20 align-items-center">
										<div className="col-lg- col-12 order-2 order-lg-1">
											<div className="details mt_md--30 mt_sm--30">
												<h4 className="title">Business Outcomes</h4>
												<p>
													Certified Mobile Application Testers who have
													passed the Foundation Level “Certified Mobile
													Application Testing” exam should be able to
													accomplish the following Business Objectives:
												</p>
												<ul className="liststyle">
													{showBusinessOutcomes}
												</ul>
											</div>
										</div>
									</div>
									{/* End Single Area */}

									{/* Start Single Area */}
									<div className="row sercice-details-content pb--20 align-items-center">
										<div className="col-lg- col-12 order-2 order-lg-1">
											<div className="details mt_md--30 mt_sm--30">
												<h4 className="title">More Information</h4>
												<p>
													Training is available from Accredited
													Training Providers (classroom, virtual, and
													e-learning). We highly recommend attending
													accredited training as it ensures that an
													ISTQB® Member Board has assessed the
													materials for relevance and consistency
													against the syllabus.
													<br />
													Self-study, using the syllabus and
													recommended reading material, is also an
													option when preparing for the exam.
													<br />
													<b>
														Holders of this certification may choose
														to proceed to other Core, Agile, or
														Specialist stream certifications.
													</b>
												</p>
											</div>
										</div>
									</div>
									{/* End Single Area */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* End Page Wrapper */}

			{/* Start Back To Top */}
			{showBackToTop && (
				<div className="backto-top" style={{ display: showBackToTop ? "block" : "none" }}>
					<div>
						<div onClick={scrollToTop} className="backto-top">
							<FiChevronUp />
						</div>
					</div>
				</div>
			)}
			{/* End Back To Top */}

			<Footer />
		</>
	);
}
